<template>
  <div class="heading3">
    <div class="title">{{content}}</div>
  </div>
</template>

<script>
export default {
  name: 'assist-heading',
  props: {
    content: {
      type: String
    }
  }
}
</script>

<style lang="scss">
  .heading3 {
    .title {
      text-align: left;
      font-size: 18px;
      font-weight: 600;
      color: #1d1d1d;
      max-width: 800px;
      margin: 26px 0 8px 0;
    }
  }
</style>
