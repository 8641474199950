<template>
  <div :class="['nav', {'active': $route.path===link}]" @mouseenter="showdrop=true" @mouseleave="showdrop=false">
    <router-link :to="link" class="nav">{{name}}</router-link>
    <span v-if="droplist" :class="['junqi', 'arrow-down', {'hover animated': showdrop}]"></span>
    <div v-if="droplist&&showdrop" class="work-dropdown animated fadeInDown">
      <router-link v-for="(p, i) in droplist" :key="i" :to="p.link">{{p.name}}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NavigationItem',
  props: {
    name: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    droplist: {
      type: Array
    }
  },
  data () {
    return {
      showdrop: false
    }
  }
}
</script>
<style lang="scss">
  @import "../styles/variables.scss";
  .nav-item {
    .nav {
      position: relative;
      flex: auto;
      flex-wrap: nowrap;
      flex-direction: row;
      text-decoration: none;
      text-align: center;
      font-size: 16px;
      color: #2c3e50;
      margin: 0 auto;
      line-height: 60px;
      &:hover .arrow-down {
        animation-name: rotateDown;
      }
      .arrow-down {
        display: inline-block;
        font-weight: 700;
        color: #1d1d1d;
        padding-left: 10px;
        &.hover {
          animation-name: rotateDown;
        }
      }
      a {
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        color: #1d1d1d;
      }
      &.active {
        border: #000000 4px solid;
        margin: 0 4px;
      }
      .work-dropdown {
        position: absolute;
        border-radius: 10px;
        top: 80px;
        left: 50%;
        transform:translate(-55%,-0%);
        width: max-content;
        background-color: rgba(225, 225, 225, 0.8);
        margin: 0 auto;
        overflow: hidden;
        a {
          &:hover {
            color: #aaaaaa;
          }
          color: #000000;
          height: 40px;
          line-height: 40px;
          display: block;
          padding: 4px 20px;
          border-bottom: none !important;
          &.router-link-active {
            background-color: rgba(28, 28, 28, 0.65);
            color: #f1f1f1;
            &:hover {
              color: #aaaaaa;
            }
          }
        }
      }
    }
  }
  @media(min-width: $md) {
    .nav-item {
      .nav {
        line-height: 100px;
        a.nav {
          transition: 0.2s all linear;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            width: 0;
            height: 100%;
            border-bottom: 2px #000000 solid;
            transition: 0.2s all linear;
          }
        }
        &.active {
          color: #2c3e50;
          background: none;
          border: none;
          a {
            border-bottom: 2px #000000 solid;
          }
        }
        &:hover {
          a {
            border-bottom: 2px #000000 solid;
            &::before {
              left: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
