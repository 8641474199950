<template>
<div class="background" :style="'background: url(' + intro.bg + ') center center no-repeat;'">
  <Navigation/>
  <div class="intro">
    <div class="affiliation"><img :src="intro.img" /><div v-html="intro.affiliation"></div></div>
    <div class="proj-name">{{intro.name}}</div>
    <SubContent name="Team" :content="intro.team"/>
    <SubContent name="Role" :content="intro.role"/>
    <SubContent name="Time" :content="intro.duration"/>
  </div>
  <ScrollDown />
</div>
</template>

<script>
import Navigation from './NavigationHead.vue'
import ScrollDown from './ScrollDown'
import SubContent from '../components/SubContent'
export default {
  name: 'ProjectIntro',
  components: {ScrollDown, Navigation, SubContent},
  props: {
    title: {
      type: String
    },
    intro: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.background {
  position: relative;
  height: 100vh;
  width: 100%;
  background-size: cover !important;
  .intro {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);;
    display: block;
    text-align: left;
    .affiliation {
      width: 100%;
      height: 30px;
      div {
        float: left;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        margin-left: 14px;
        color: #aaaaaa;
        span {
          color: #000000;
          font-weight: 500;
        }
      }
      img {
        height: 22px;
        margin: 0 0 0 10px;float: left;
      }
    }
    .proj-name {
      font-size: 40px;
      font-weight: 600;
    }
    @media(min-width: $lg) {
      left: calc(50% - 700px);
      width: 800px;
    }
  }
}
</style>
