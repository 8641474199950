<template>
  <div class="works">
    <Navigation/>
    <div id="background">
      <div class="info">
        <div class="name">
          <span class="ini">Hi!</span> I'm Junqi, a product manager with fullstack digital product development experience. I had been
          <typed-text :strings="titles" :loop="true" :typeSpeed="100">
            <span class="intro typing"></span>
          </typed-text>
        </div>
      </div>
      <svg class="svg-mark" width="1548" height="1549" viewBox="0 0 1548 1549" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1153.2 11.676L1210.83 0L1547.44 796.676H1213.2V1548.68H1153.2V796.676H799.699V1132.96L0 794.296L11.699 736.676H739.699V476.676H327.199V416.676H799.699V736.676H1153.2V11.676ZM1213.2 736.676H1456.96L1213.2 159.76V736.676ZM739.699 796.676H159.472L739.699 1042.4V796.676Z" fill="#E9E9E9"/>
      </svg>
    </div>
    <div class="heading-container" @click="scrollTop" >
      <Heading1 content="Selected Works <span class='junqi arrow-handle'/>" />
    </div>
    <div class="content">
      <Widget v-for="(i, k) in items" :key="k" :pic="i.pic"
              :to="i.url" :tags="i.tags" :title="i.name" :detail="i.detail" :affiliation="i.affiliation" />
    </div>
    <Footer page-name="Home"/>
  </div>
</template>
<style lang="scss" src="./Works.scss"></style>
<script>
import Navigation from '../components/NavigationHead.vue'
import Widget from '../components/Widget'
import Footer from '../components/GlobalFooter.vue'
import Heading1 from '../components/PrimaryHeading.vue'
import TypedText from "@/components/TypedText.vue";
import {data} from "@/config/home-screen.config";

export default {
  name: 'home-page',
  components: {Heading1, Footer, Navigation, Widget, TypedText},
  data () {
    return data
  },
  methods: {
    scrollTop () {
      console.log('test')
      let timer = setInterval(function () {
        let scrollTop = document.documentElement.scrollTop
        let ispeed = Math.floor(window.innerHeight / 100)
        if (scrollTop >= (window.innerHeight - 160)) {
          clearInterval(timer)
        }
        document.documentElement.scrollTop += ispeed
      }, 0)
    }
  },
  mounted () {
  }
}
</script>
