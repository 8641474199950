<template>
    <div class="netease fadeInUp animated">
      <ProjectIntro :intro="intro"/>
      <div class="content">
        <Heading1 content="The Project"/>
        <p class="pf">
          The NetEase News app introduces a new feature—an algorithm-free, customizable news feed on its homepage. The primary objective of this feature is to furnish users with up-to-the-minute information, tailored to their preferences and interests. By employing a non-algorithmic approach, NetEase News endeavors to deliver timely news updates that cater precisely to the needs of its users.
        </p>
        <Heading2 content="The Process"/>
        <img src="../assets/netease/process.svg" alt="process" style="width:1400px;"/>
        <Heading1 content="Product Overview"/>
        <p class="pf">
          NetEase, a leading technology company in China, boasts exceptional prominence. Among its accomplishments, NetEase News, a news application and its first product, stands out as one of the top three in the country, with an daily active user base of 10 million.
        </p>
        <p class="pf">
          In China, the landscape of news applications is markedly distinct. These platforms gravitate towards a captivating blend of entertainment and information, setting them apart from their counterparts in the United States. Combining the features of news aggregation and social media, these Chinese mobile products exhibit a unique amalgamation of functionalities that appeal to a broad audience.
        </p>
        <div class="intro-grid">
          <div class="one-col">
            <Heading2 content="Product Structure" />
            <img src="@/assets/netease/structure.svg" alt="product architecture" style="width:555px;"/>
          </div>
          <div class="one-col">
            <Heading2 content="Business Model" />
            <img src="@/assets/netease/business_model.svg" alt="business model" style="width:370px;"/>
          </div>
        </div>
        <Heading2 content="Types of Content" />
        <img src="@/assets/netease/content.svg" alt="content" style="width:60%;"/>
        <Heading1 content="Problem Space"/>
        <p class="pf">This project sought to uncover additional avenues for future product development by addressing user pain points. </p>
        <Heading2 content="The Headline" />
        <div class="pbl-screen">
          <div class="one-col child-center"><img src="https://storage.googleapis.com/junqi-portfolio/netease/homepage.png" alt="homepage with annotation" style="width:580px;" /></div>
          <div class="one-col">
            <heading3 content="The Upper Part: Pinned Communist Party Political Propaganda" />
            <ul class="ul">
              <li>News in China, like various other content categories, faces stringent regulations. The dominant landscape is occupied by party-mandated propaganda content. To cater to users seeking more in-depth journalism, the timeline feature provides a convenient and accessible avenue for consumption.</li>
              <li>However, the recommendation feed encompasses a wide range of content, including user-generated posts, reviews, and editorials, in addition to general news. Consequently, users lack a streamlined overview of daily news updates.</li>
            </ul>
            <heading3 content="The Lower Part: Entertainment-Oriented Recommendation Stream" />
            <ul class="ul">
              <li>The current homepage features a recommendation feed driven by our algorithm, offering limited user control over desired content.</li>
              <li>This design encourages users to spend extended periods browsing, rather than obtaining a quick overview.</li>
              <li>The recommendation feed lacks chronological ordering, hindering timely news consumption for users.</li>
            </ul>
          </div>
        </div>
        <Heading2 content="The App Overall" />
        <div class="pbl">
          <div class="pbl-container">
            <div class="ti-box">
              <img src="@/assets/news.svg" >
              <div class="title">Inaccessible High-Quality Journalism</div>
            </div>
            <div>
              <ul class="ul">
                <li>User research indicates that individuals in China seeking such content tend to subscribe to individual creators or news agency branch studios on social media, rather than relying on digital news apps.</li>
                <li>Our app falls short in delivering high-quality journalism. This can be attributed to prevalent consumer behaviors in the Chinese internet landscape and the app's evolution towards an entertainment-focused social media platform amidst the continuous growth of NetEase News.</li>
              </ul>
            </div>
          </div>
          <div class="pbl-container">
            <div class="ti-box">
              <img src="@/assets/algorithm.svg" >
              <div class="title">Little Differentiation with Competitors</div>
            </div>
            <div>
              <ul class="ul">
                <li>NetEase News currently lacks significant differentiation from its competitors. The three major news apps in the market share a similar user profile and heavily rely on recommendation algorithms.
                  To stand out and attract a distinct user base, implementing this feature can provide the much-needed differentiation for NetEase News, setting it apart from other prominent news products.</li>
              </ul>
            </div>
          </div>
        </div>
        <Heading1 content="Objective" />
        <div class="highlight-box">How might we create a feature for users to efficiently and effectively know the day?</div>
        <ul class="ul">
          <li>Provide an alternative to information overload.</li>
          <li>Save time for users who just want to take a quick peek to know the day.</li>
          <li>Aim to inform users but not to distract users. </li>
          <li>Expand to include more users with a tight schedule and higher disposable income to our user base.</li>
          <li>Expand product matrix and explore future product possibilities outside our current space.</li>
        </ul>
        <Heading1 content="Feature Flow"/>
        <p class="pf">The proposed idea (by the product manager and the leadership) involves incorporating a timeline feature curated by our editors, accessible from the homepage. Users will have the freedom to customize the content based on their preferred topics.</p>
        <img src="@/assets/netease/flow.svg" style="width:555px;" class="mt-30"/>
        <Heading1 content="Persona" />
        <p class="pf">Since the target user is not our core user base, to streamline our design process and effectively cater to the target audience, we developed a persona based on previous user research reports for the timeline feature. Our target user base comprises educated young professionals.</p>
        <img src="https://storage.googleapis.com/junqi-portfolio/netease/persona.png" class="dsd mt-30" style="width:90%;" alt="">
        <p class="pf">Based on the research team's user research report, I developed a persona that serves as a valuable design guide. The persona highlights the need for our product to prioritize efficiency, effectiveness, and maximizing the signal-to-noise ratio. Specifically, our focus should be on enabling our target users, educated young professionals, to quickly access essential news information.
        </p>
        <p class="pf">
          To achieve this, we can concentrate on two key aspects:
        </p>
        <ol>
          <li>Streamlining the daily consumption process,</li>
          <li>Enhancing feed quality to condense information.</li>
        </ol>
        <p class="pf">From a UX design standpoint, my primary emphasis lies on implementing the first solution.</p>
        <Heading1 content="Impact Estimation & Metrics Determination"/>
        <img src="../assets/netease/metrics.svg" style="width:444px;"/>
        <p class="pf">
          Considering that our target user base for this feature differs from our app's primary user base, traditional user engagement metrics such as UV (unique visitors) may not accurately measure the success of our product. It is expected that overall user activity will be lower compared to other core features.
        </p>
        <p class="pf">Furthermore, as the primary goal is to maximize efficiency, metrics such as click-through-rate of landing page cards and viewing time are less relevant.</p>
        <p class="pf">Instead, our focus should be on user retention. Given that the core user base is not our primary audience and the feature's location is highly noticeable, initial drop-off rates are expected to be high, with potentially lower engagement levels thereafter. Therefore, average PV (page views) per user and the week retention rate for users who engage with the feature at least three days a week, once data stabilization occurs, will serve as our Northstar metrics.</p>
        <p class="pf">Considering the relatively simple development cost and feature structure in this prime position, we have decided to prioritize software implementation and a partial release based on rough product requirements and design. This will enable us to leverage real user data for further optimization and validation of our current and future design, rather than conducting extensive research.</p>
        <Heading1 content="Exploration One" sub="How to Organize the Timeline Feed"/>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/E1.png" alt="">
          </div>
          <div class="one-row override-800">
            <div>
              <Heading2 content="Multiple Tabs" />
              <p class="pf">Introducing multiple tabs offers a categorized view of content. The "All" tab consolidates all available tabs, while the "Breaking" tab displays news deemed "important" by our editors across all tabs.</p>
              <Heading3 content="Pros"/>
              <ul class="ul">
                <li>Enables users to easily navigate and view content in a categorized manner.</li>
                <li>Facilitates browsing through each category before selecting desired channels.</li>
              </ul>
              <Heading3 content="Cons"/>
              <ul class="ul">
                <li>If the Timeline feature is appropriately personalized, manual category switching becomes unnecessary.</li>
                <li>Manual category switching contradicts our goal of "brief browsing," as it involves a high-cost activity.</li>
                <li>Users may struggle to differentiate between homepage feed tabs and timeline tabs due to visual similarities.</li>
                <li>Potential difficulty in mental model switching, as users may have trouble understanding if the "all" tab aggregates
                  all content or just the content from the listing tabs. The mental model applied to the customized timeline might be influenced
                  by the homepage "Headline" tab, which aggregates content but is not directly related to the listing tabs. </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row"><img src="https://storage.googleapis.com/junqi-portfolio/netease/E2.png" alt=""></div>
          <div class="one-row">
            <div class="vertical-center">
              <Heading2 content="A Simple Recommendation Feed<span>Selected</span>"/>
              <Heading3 content="Pros"/>
              <ul class="ul">
                <li>Highlights the "Customizable" feature, emphasizing user control and personalization.</li>
              </ul>
              <Heading3 content="Cons"/>
              <ul class="ul">
                <li>Users may find it more challenging to switch between topics.</li>
                <li>Perceiving the ability to change topic selection may be more difficult for users.</li>
                <li>Users may not have the ability to quickly browse topics that are not included in their selection.</li>
              </ul>
            </div>
          </div>
        </div>
        <Heading1 content="Exploration Two" sub="How to Select Topics"/>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/E3.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Representing Topics in Capsules"/>
              <Heading3 content="Pros"/>
              <ul class="ul">
                <li>Resembles the familiar homepage channel selection, reducing cognitive load for users.</li>
                <li>Displays background content with less disruption to the main workflow of reading news.</li>
              </ul>
              <Heading3 content="Cons"/>
              <ul class="ul">
                <li>The similarity to our homepage channel editing may confuse users.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/E4.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Representing Topics in a List<span>Selected</span>"/>
              <p class="pf"></p>
              <Heading3 content="Pros" />
              <ul>
                <li>Provides a visual emphasis on topic selection, showcasing the "Customizable" and "Exclusive" features.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>May appear visually empty or lack visual appeal.</li>
                <li>Users may find it harder to reach the top of the screen with their fingers.</li>
              </ul>
            </div>
          </div>
        </div>
        <Heading1 content="Exploration Three" sub="Trade-offs between the Conversion and Customization"/>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/E6.gif" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Automatically Load with Default Topics"/>
              <p class="pf"></p>
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>Reduces conversion steps, allowing users to consume content immediately after clicking through.</li>
                <li>Users can make topic selections, which is a higher-cost task, at a later stage.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>Not offering users the option of topic selection during onboarding makes it more challenging to convey the concept of customization to users.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/E5.gif" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Require Users to Select Manually<span>Selected</span>"/>
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>A good opportunity to educate users to select their favorite topics. </li>
                <li>Emphasizing "customizable", leaving users the autonomy to make their own decisions.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>Users can't preview content before they make decisions.</li>
                <li>Too many conversion steps. Especially for users who don't even know what they are walking into when they are doing the selection.</li>
              </ul>
            </div>
          </div>
        </div>
        <Heading1 content="Final Design"/>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/P1.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Entry Bar" />
              <p class="pf">To enhance user experience and encourage engagement, I have incorporated an update time notification on the entry bar. This addition aims to attract users to click into the feature, ensuring they are aware of when new content is available for them to read.</p>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/onboarding.gif" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Onboarding" />
              <p class="pf">
                To facilitate the onboarding process and enhance user engagement, I have made significant improvements to the design. Instead of relying on users to select topics like previous prototypes, I have introduced a form submission interaction style with a prominent red button for submission. This approach simplifies the onboarding experience while providing clearer instructions through explanatory UX copywriting.
              </p>
              <p class="pf">
                Additionally, to address the issue of visual emptiness, I have incorporated pictures and explanatory texts. These visual elements not only add visual appeal but also serve as a means to communicate the content of each topic to the user. This enriches the onboarding experience and helps users understand the available topics.
              </p>
              <p class="pf">
                Importantly, these enhancements do not add any additional steps to the onboarding process, ensuring a seamless transition for users.
              </p>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/landing.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Landing Page" />
              <p class="pf">To create a more immersive and undisturbed experience, the visual focus of the landing page has been directed towards the article list. This shift ensures that users can easily access and engage with the content without distractions.</p>
              <p class="pf">To enhance the sense of timeliness, we have introduced date and weather information on the landing page. This addition provides users with up-to-date information and fosters a greater sense of context and relevance.</p>
              <p class="pf">By maintaining a clean and uncluttered design, we aim to deliver an enjoyable and seamless browsing experience for our users.</p>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/edit.gif" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Selected Topics Editing" />
              <p class="pf">To signify the status of editing topics and create a sense of ritual, we have implemented a confirm button with purposeful copywriting. This approach emphasizes the user's active involvement in the topic customization process, enhancing their sense of control and engagement. By incorporating this feature, we aim to elevate the overall user experience and make the topic editing experience more intentional and satisfying.</p>
            </div>
          </div>
        </div>
        <Heading2 content="Testing Different Names" />
        <p class="pf">
          Recognizing that the term "timeline" is not commonly used in Chinese environments, we conducted multiple rounds of A/B testing and an informal focus group to explore alternative names for the feature. Our aim was to identify a name that would resonate with users and attract their attention.
        </p>
        <p class="pf">
          After careful consideration and analysis of user feedback, we have settled on the name "Today for You" as the brand for this feature. This name emerged as the preferred choice, demonstrating its potential to engage and appeal to our target audience.
        </p>
        <table>
          <tr>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </table>
        <Heading1 content="Sprint One Results" num="8"/>
        <Heading2 content="Metrics"/>
        <img src="https://storage.googleapis.com/junqi-portfolio/netease/conversion.png" style="width:54%;" alt="">
        <p class="pf">
          After the feature was released, we observed a low Click-Through-Rate (CTR) for the homepage bar and a significant drop-off rate during the topic selection process.
        </p>
        <Heading2 content="Reflection"/>
        <p class="pf">While we anticipated that the feature may not gain significant popularity among our main user base, it became apparent that placing a low-traffic feature in such a prominent position on the homepage was not effective.</p>
        <p class="pf">Moreover, the high drop-off rate indicates that users were not even able to fully experience the feature before deciding to quit. This suggests that the conversion funnel may have presented a barrier that was too costly for most users to proceed with.</p>
        <p class="pf">
          To address these issues, it is crucial to reevaluate the positioning and prominence of the feature and consider ways to simplify the conversion process, reducing the cost for users to engage with it effectively.
        </p>
        <p class="pf">
          I identified current problems:
        </p>
        <div class="gulf-container">
          <div class="gulf-list">
            <div>
              <h1>Gulf of Evaluation</h1>
              <ul class="ul">
                <li>The upper bar lacks enticing elements to attract users as it only displays the feature's name and the number of updated news. Users have no clear expectation of what lies inside, which fails to capture their attention sufficiently.</li>
                <li>Users lack a sense of progress or understanding of what they will encounter when they click through and arrive at the topic selection page.</li>
              </ul>
            </div>
            <div>
              <h1>Gulf of Execution</h1>
              <ul class="ul">
                <li>Users may perceive the upper part as an un-clickable decorative label rather than an interactive element.</li>
                <li>The entry bar and the lower article card are visually associated, leading users to assume that the upper part and the lower part constitute a single entry.</li>
                <li>Users lack motivation to click and explore the content since they have no visibility into what lies within.</li>
              </ul>
            </div>
          </div>
          <div class="child-center"><img src="../assets/netease/gulf.svg" alt="gulf"/></div>
        </div>
        <Heading2 content="User Journey"/>
        <p class="pf">To validate my assumptions and identify potential risks, I conducted several simple usability tests and created the following user journey map.</p>
        <img src="https://storage.googleapis.com/junqi-portfolio/netease/Journey%20Map.png" style="width:100%;" class="mt-20 dsd"/>
        <p class="pf mt-20">The touchpoints with low user experience align with the points where the drop-off rate is highest.</p>
        <Heading2 content="The Hook Canvas"/>
        <p class="pf">The existing problems can be synthesize using a hook canvas.</p>
        <div class="hook-framework">
          <div>
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/hook.png" alt="hook_model">
          </div>
          <div class="child-center">
            <table>
              <tbody>
              <tr><th>TRIGGERS</th><th>ACTIONS</th></tr>
              <tr>
                <td><span>External Trigger:</span> things happening around the world can have an impact on individuals.<br/>
                  <span>Internal Trigger:</span> The decision-making in my career requires me to know the macro environment well; I am curious about the world. </td>
                <td>You click into the timeline feature, complete the topic selection, and start to browsing news picked by our editors. </td>
              </tr>
              <tr>
                <td>You check the timeline more and more frequently and started using other social media subscriptions for news reading.</td>
                <td>Efficiently and effectively find the news you need for the day.</td>
              </tr>
              <tr><th>INVESTMENTS</th><th>REWARDS</th></tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="pf">We can see that triggers are not directly bridged to actions. Users are most likely to click into the timeline feature out of random curiosity instead of being interested in the feature itself.</p>
        <Heading2 content="Next Iteration Objectives"/>
        <div class="nxt-obj">
          <div>Add more timeline perception at the entry</div>
          <div>Enhance the independence of the entry</div>
          <div>Enhance timeline content perception during topic selection</div>
        </div>
        <Heading1 content="Sprint Two Improvements"/>
        <Heading2 content="Improving the Entry Card Conversion and Perception" />
        <p class="pf">During our design process, we acknowledged the potential usability and awareness issues associated with the current entry component. However, we chose to maintain this design to avoid obstructing ad exposure, even though we anticipated certain metrics to be suboptimal. However, the actual impact has been worse than anticipated.</p>
        <p class="pf">
          To address these challenges, I have come up with three possible solutions:
        </p>
        <div class="one-screen-content two-screen-override mt-30">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/s2-3-1.gif" alt="">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/s2-3-2.gif" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Repurpose the lower bar" />
              <p class="pf">Transform the lower bar into a shortcut that leads to an article in the timeline. Clicking on this bar would direct users to the timeline landing page with the article card positioned at the top of the scrolling area.</p>
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>Combining the traffic of two cards can significantly increase the user traffic of the timeline landing page.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>Inconsistent interaction mental models: Clicking other article cards redirects users to article detail pages, whereas clicking this card leads to the timeline landing page. This may create usability issues.</li>
                <li>Additional steps for users to access the desired content, resulting in inefficiency and unnecessary complexity.</li>
                <li>This solution doesn't address any underlying problems; it merely tricks users into clicking into the feature.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/s2-4.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Increase the entry bar area<span>Selected</span>" />
              <p class="pf">
                Separate the entry bar from the lower article bar and incorporate a carousel of recent article titles. Expand the brand display area to provide a larger visual presence.
              </p>
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>Establishes an independent and fixed entry point, contributing to building brand image and user habits for this feature.</li>
                <li>The increased area has more visual significance and creates better perception of the feature, making its purpose easier to understand.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>Takes more time to attract users compared to Solution One, as users must consciously choose to click into the timeline.</li>
                <li>Occupies additional vertical space, potentially blocking the exposure of lower ads and posing challenges to one of our major ad revenue areas.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/s2-5.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Separate the entry bar from the lower bar" />
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>Offers an independent entry point without occupying more space. </li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>VVisually inconsistent with other cards in the headline recommendation feed.</li>
                <li>Appears less effective as it fails to address many of the underlying issues, leaving several problems unresolved.</li>
              </ul>
            </div>
          </div>
        </div>
        <Heading3 content="Leveraging the Conflict between Card Area and Ads Exposure"/>
        <p class="pf">To thoroughly evaluate the impact of increasing the height of the entry card, I propose conducting a comprehensive analysis by querying users' device dimension data. This will help us assess the relationship between the device screen height and the exposure of ads. Understanding this relationship will provide valuable insights into the potential impact on monetization.</p>
        <p class="pf">As an alternative approach, if the monetization product team can not accommodate the increased height, we can consider moving the article entry below the timeline entry card. By placing it below the ads section, we can strike a balance between optimizing the card area and maintaining ad exposure.</p>
        <p class="pf">This solution ensures that both the entry card and the ads receive appropriate visibility, enhancing the user experience while considering revenue generation. Careful analysis and consideration of the data will enable us to make an informed decision that benefits both users and our monetization efforts.</p>
        <Heading2 content="Improving Conversion Rate after Users Clicked Through" />
        <p class="pf">
          After careful analysis, I have identified the current problem: the full-screen and mandatory selection step in the onboarding process fails to give users a taste of the product feature before making selections.
        </p>
        <p class="pf">To address this, we need to find a way to emphasize the "customizable" feature while lowering the conversion cost. I have devised two potential solutions:</p>
        <div class="one-screen-content mt-80">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/s2-1.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Half-screen popup box<span>Selected</span>" />
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>By maintaining a visible landing page during the onboarding process, users can develop a better understanding of the feature and build higher expectations.</li>
                <li>Even if users choose to quit the topic selection popup, they will still have to view the landing page, providing more opportunities for them to explore the feature before fully quitting.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>This approach may result in less emphasis on the "customization" aspect in terms of visual and interaction design.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="one-screen-content">
          <div class="one-row">
            <img src="https://storage.googleapis.com/junqi-portfolio/netease/s2-2.png" alt="">
          </div>
          <div class="one-row">
            <div>
              <Heading2 content="Latest article preview for each topic" />
              <p class="pf">Add an article preview for each topic.</p>
              <Heading3 content="Pros" />
              <ul class="ul">
                <li>Including an article preview for each topic can partially address users' lack of perception regarding the content of each topic.</li>
              </ul>
              <Heading3 content="Cons" />
              <ul class="ul">
                <li>This solution may introduce a higher cognitive load for users due to the text-heavy nature, potentially leading to visual clutter and increased drop-off rates.</li>
                <li>The improvement in users' perception of the final feature functionality may be limited.</li>
              </ul>
            </div>
          </div>
        </div>
        <Heading2 content="Quick and Dirty Testing"/>
        <p class="pf">
          To validate my previous assumptions and advocate for my preferred solution, I conducted quick and informal testing with randomly recruited colleagues who have no connection or experience with product development processes.
          I sought their opinions on proposed solution prototypes and the current workflows, randomizing the order of each solution and the current design to avoid any bias of order effect.
        </p>
        <p class="pf">
          The findings from the testing are as follows:
        </p>
        <ul class="ul">
          <li>All participants preferred Solution Two for the entry bar design over other solutions, indicating a clear preference for its visual presentation.</li>
          <li>7/8 of them preferred Solution One for the original topic editing page design, as it allowed them to understand the feature before making compulsory choices.</li>
          <li>6/8 of them believed that Solution Two provided more information about each topic, but they also found it more confusing in terms of understanding how the feature works.</li>
          <li>All participants found Solution Two for the original topic editing page design to be too text-heavy and less visually pleasing.</li>
          <li>For Solution One in entry bar design, participants expressed confusion when they realized that clicking the article title led to the timeline landing page.</li>
        </ul>
        <Heading1 content="Metrics & Impact"/>
        <p class="pf">Following the release of Sprint Two, we observed significant positive outcomes in key metrics:
        </p>
        <ul class="ul">
          <li>Conversion Rate: The conversion rate experienced a remarkable increase of 21%, indicating a higher percentage of users successfully completing the desired actions or conversions.</li>
          <li>Daily Active Users: We noted a substantial 8% increase in the number of daily active users. This demonstrates improved engagement and a growing user base.</li>
        </ul>
        <p class="pf">
          After three months, we assessed the following stabilization in metrics:
        </p>
        <ul class="ul">
          <li>User Retention Rate: The user retention rate settled around 40% and displayed a slightly increasing trend. This suggests that a considerable portion of users continues to engage with our product over an extended period.</li>
          <li>Average PV per User: The average page views per user stabilized at around 1.2. This metric indicates the level of engagement and content consumption by users, remaining consistent over time.</li>
        </ul>
        <div class="data-demo data-4">
          <div class="col">
            <div class="title">Conversion Rate</div>
            <div class="data">+21%</div>
          </div>
          <div class="col">
            <div class="title">DAU</div>
            <div class="data">+8%</div>
          </div>
          <div class="col">
            <div class="title">User Retention Rate</div>
            <div class="data">40%</div>
          </div>
          <div class="col">
            <div class="title">Daily PV per User</div>
            <div class="data">1.2</div>
          </div>
        </div>
      </div>
      <Footer page-name="netease"/>
    </div>
</template>

<script>
import Heading1 from '../components/PrimaryHeading.vue'
import Heading2 from '../components/SecondaryHeading.vue'
import Heading3 from '../components/AssistHeading.vue'
import Footer from '../components/GlobalFooter.vue'
import ProjectIntro from '../components/ProjectIntro'
import {useMeta} from "vue-meta";
export default {
  name: 'netease-page',
  components: {ProjectIntro, Footer, Heading3, Heading2, Heading1},
  setup () {
    useMeta({
      title: 'NetEase',
      description : "Junqi was an interaction designer for NetEase News mobile app",
      twitter: {
        title: "NetEase | Junqi Wang",
        description: "Junqi was an interaction designer for NetEase News mobile app",
        card: "twitter card",
        image: "https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg",
      },
      og: {
        title : 'NetEase',
        description : 'Junqi was an interaction designer for NetEase News mobile app',
        type : 'website',
        url : 'https://junqi.info/netease',
        image : 'https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg',
        site_name : 'Junqi Wang',
      }})
  },
  data () {
    return {
      intro: {
        name: 'Customizable Timeline',
        img: 'https://storage.googleapis.com/junqi-portfolio/netease/news_logo.png',
        affiliation: 'NetEase | <span>NetEase News</span>',
        bg: 'https://storage.googleapis.com/junqi-portfolio/netease/netease_cover.png',
        team: 'NetEase News Contracted Originality Center | Interaction Design Team',
        role: 'Interaction Designer',
        duration: 'Dec. 2020 - Jun. 2021'
      }
    }
  }
}
</script>
<style lang="scss">
  .netease {
    .content {
      text-align: left;
    }
    .background {
      padding-bottom: 100px;
      margin-bottom: -100px;
      .scroll-down {
        bottom: 114px;}
    }
    .intro-grid {
      display: grid;
      grid-template-columns: 3fr 2fr;
      text-align: left;
      max-width: 1100px;
      .one-col:last-child {
        position: relative;
        img {
          position: relative;
          left: 0;
          margin: 15% 16% 0 0;
        }
      }
    }
    .pbl-screen {
      display: grid;
      grid-template-columns: min-content auto;
      column-gap: 60px;
      .one-col {
      }
    }
   .pbl {
     display: grid;
     grid-template-columns: 3fr 2fr;
     column-gap: 80px;
     margin-bottom: 40px;
   }
    .pbl-container {
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      display: grid;
      grid-template-rows: min-content auto;
      width: 100%;
      background-color: #ffffff;
      img {
        width: 60px;
        padding: 20px;
      }
      .title {
        font-weight: 500;
        font-size: 24px;
        width: 400px;
        padding: 20px;
      }
      .ti-box {
        display: flex;
      }
    }
    .gulf-container {
      display: grid;
      grid-template-columns: 3fr 2fr;
      column-gap: 80px;
      margin: 30px 10px;
      .gulf-list {
        display: grid;
        row-gap: 30px;
        h1 {
          font-weight: 500;
          font-size: 20px;
          text-align: left;
        }
        div {
          padding: 20px;
          border-radius: 20px;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
          background-color: #ffffff;
        }
      }
      img {
        width: 100%;
      }
    }
    .nxt-obj {
      display: grid;
      grid-template-columns: auto auto auto;
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
      div {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 180px;
        background-color: #ffffff;
        border-radius: 140px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        font-weight: 500;
        font-size: 18px;
        padding: 10px;
      }
    }
    .hook-framework {
      display: grid;
      grid-template-columns: 2fr 3fr;
      font-size: 16px;
      margin: 30px 20px;
      table {
        border-collapse: collapse;
        width: 100%;
        border-spacing: 0;
        td {
          text-align: left;
          padding: 16px;
          border: #bbbbbb 1px solid;
          span {
            font-weight: 500;
          }
        }
        th {
          padding: 16px;
          font-weight: 600;
          text-align: left;
          border: #bbbbbb 1px solid;
          &:nth-child(2) {
            text-align: right;
          }
        }
      }
    }
    .one-screen-content {
      .one-row:last-child {
        width: min-content;
        min-width: 560px;
        max-height: 800px;
        &.override-800 {
          width: 800px;
        }
      }
    }
    .s2-prototypes {
      display: flex;
      flex-direction: row;
      div {
        width: 50%;
      }
    }
    .heading1 {
      .name {
        color: #272733;
      }
      .lower-bar {
        background-color: #b1b1b1;
      }
    }
    .data-demo {
      .col {
        .data {
          color: #EE1A1A;
        }
      }
    }
  }
</style>
