<template>
<div class="cine-ex fadeInUp animated">
  <ProjectIntro :intro="intro"/>
  <div class="content">
    <p class="pf first">
      This is my BUPT-QMUL final year project, supervised by Prof. Wei Sun from School of Digital Media and Design Arts, BUPT. My task is to design
      and develop a connectivity platform for local movie audiences.
      In this case, it is designing a movie audience connectivity platform for Beijing. I should find features that are needed by the
      local audience.
    </p>
    <Heading1 content="Challenge"/>
    <p class="pf">
      What is essential to this app is that it is supposed to be local, but there have already been various apps of movies
      reviews and discussion and a large user base nationwide. As a movie is usually distributed simultaneously nationwide,
      there doesn't seem to be much need for a place for "local" movie audiences to connect. I don't see the difference between
      going to a movie in Beijing and in Chongqing. Some features may be "good to have," but nationwide platforms can provide better
      experience as there is a larger user base, which results in more active usage.
    </p>
    <div class="highlight-box">How might we create or optimize a film-going experience that can be specifically achieved local?</div>
    <Heading1 content="Research"/>
    <Heading2 content="Interviews" />
    <p class="pf">
      To answer this question, I conducted interviews with 10 moviegoers in Beijing from all walks of life, ages 20 to 35.
      The interview was semi-structured, and all the questions were open-ended, just to make sure people could think out loud
      and share more details on their movie-going experience, how their movie-related information is obtained, and their opinion
      on movie-related digital products. During the interview, I found that most moviegoers feel content with the information
      provided by available movie platforms. Their movie-going journey can be concluded as:
    </p>
    <div class="left-img mb-80 mt-50">
      <img src="../assets/cine/stages.svg" alt="" style="width:71.4%;"/>
    </div>
    <p class="pf">
      However, I discovered that things were different with a certain group of people: cinephiles. Unlike general audiences,
      cinephiles are interested in films, film theory, and film criticism. They value films a lot in their daily life. Aside
      from public release movies, they are more interested in art films, film-related seminars, salons of directors and film
      critics, and group film watching. One of the unpleasant parts of their movie-related experience they mentioned is that
      they often found they couldn't make it to the movie. How can that be? They told me that art cinemas usually open booking
      really early in advance of the film’s release. Their journey is like this:
    </p>
    <div class="left-img mb-80 mt-50">
      <img src="../assets/cine/stages-2.svg" alt="" style="width:90%;"/>
    </div>
    <p class="pf">
      I also learned that events like film festivals could greatly boost the sales of movie tickets. A cinephile told me
      that during Beijing Film Festival, he failed to buy a ticket to a classic film, but the film was screened later a few
      weeks later in an art cinema. It was the same film, but the seats were half empty. I later asked other cinephiles about
      their opinion on film events, and they gave similar opinions. They said that they went to cinema instead of
      watching online streaming is because they liked the sense of ritual, and film events gave them more of that.
    </p>
    <div class="two-column">
      <div>
        <p class="pf">
          Thus, I think ticket resale might be a pain point for Beijing cinephiles.
        </p>
      </div>
      <div class="findings-demo">
        <div class="title">Events</div>
        <div class="title">Ticket Booking</div>
        <div class="title">Arthouse</div>
      </div>
    </div>
    <Heading2 content="Secondary Research" />
    <p class="pf">
      Based on previous results, I have done some background research along with reframed questions to understand the pain
      points of cinephiles better.
    </p>
    <Heading3 content="The Market" />
    <div class="column-2-3">
      <div class="one-col align-right mt-20"><v-chart class="pie-chart" :option = "opt_pie" ></v-chart></div>
      <div class="one-col">
        <div>
          <p class="pf">
            In the first quarter of 2019, online channels have become the main channel
            of ticket purchasing, and offline channels account for only 14.3% of the market share of movie ticket purchasing. Maoyan
            and Taopiaopiao accounted for 42.6% and 31.5% of the Chinese movie ticket market, respectively, which constitute a duopoly
            in the China Movie ticket selling market.
          </p>
          <div class="reference">* iiMeida Culture and Entertainment Research. “IiMedia: 2019 Q1 China Online Movie Ticket Sale Report.”
            iiMeida. Accessed October 21, 2019. https://www.iimedia.cn/c400/64126.html. </div>
        </div>
      </div>
    </div>
    <div class="two-column mt-80 mb-80">
      <div class="one-row">
        <div>
          <Heading3 content="Industry Regulation" />
          <p class="pf">
            In June 2018, China Association of Film Publication and Screening introduced Regulation of Movie Ticket Refund and
            Reschedule, which prompted the majority of movie theater chains and major movie ticket booking platforms to adopt a
            transparent policy and support reschedule or refund of booked movie tickets within a certain timespan before the movie
            is screened.
          </p>
        </div>
      </div>
      <div class="one-row">
        <img class="regulation" src="https://storage.googleapis.com/junqi-portfolio/cineex/regulation-1.jpg" alt="regulation 1" />
        <img class="regulation" src="https://storage.googleapis.com/junqi-portfolio/cineex/regulation-2.jpg" alt="regulation 2" />
      </div>
    </div>
    <Heading3 content="Current Policies" />
    <p class="pf">
      The duopoly — Taopiaopiao and Maoyan adopted similar policies for ticket refund and change. Nearly all cinemas listed tickets
      complied with two platforms' policies.
    </p>
    <div class="policy-tables">
      <div class="one-col child-center">
        <table>
          <tr>
            <th rowspan="0"><div class="title"><img src="https://image.pitchbook.com/4xOUIPsVtkENVtuncQVVwSxtTAx1463419362617_200x200" /><div>Taopiaopiao</div></div> </th>
            <th>Membership Level</th>
            <th>Refundable Orders per Month</th>
            <th>Reschedulable Orders per Month</th>
          </tr>
          <tr>
            <td>Normal</td>
            <td>1</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Bronze</td>
            <td>2</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Gold</td>
            <td>3</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Diamond</td>
            <td>5</td>
            <td>5</td>
          </tr>
        </table>
      </div>
      <div class="one-col child-center">
        <table>
          <tr>
            <th rowspan="4"><div class="title"><img src="../assets/cine/maoyan.png" /><div>Maoyan</div></div></th>
            <th>Membership Level</th>
            <th>Refundable Orders per Month</th>
            <th>Reschedulable Orders per Month</th>
          </tr>
          <tr>
            <td>V1-V2</td>
            <td>1</td>
            <td>2</td>
          </tr>
          <tr>
            <td>V3-V4</td>
            <td>2</td>
            <td>2</td>
          </tr>
          <tr>
            <td>V5</td>
            <td>3</td>
            <td>3</td>
          </tr>
        </table>
      </div>
    </div>
    <Heading3 content="Special Cases" />
    <p class="pf">
      However, I found that this is only common in public release films in many commercial theaters. On other occasions,
      including film festivals, arthouse cinemas, and special screening events, theaters support neither refund nor reschedule.
    </p>
    <div class="polar-chart-grid">
      <div>
        <div class="polar-legend">
          <div class="bar"><div class="red"></div> <span>ARTHOUSE</span></div>
          <div class="bar"><div class="black"></div> <span>COMMERCIAL</span></div>
        </div>
        <v-chart class="polar-chart" :option = "opt_cine" ></v-chart>
      </div>
      <div class="child-center">
        <div>
          <Heading3 content="The Time when Tickets Become Available for Purchase before Screening"/>
          <p class="pf">
            Moreover, as in China, regulations on movie screenings are stringent, which resulted in high demand in art films in
            Beijing but relatively low supply in art cinemas. Film festivals, arthouse cinemas, and screening events usually
            schedule screenings very early and open ticket booking even over a month before. It results, on the one hand, in many
            audiences booking tickets that they wouldn't have time to watch. On the other hand, a lot of people ended up failing
            to get the tickets they want.
          </p>
        </div>
      </div>
    </div>
    <Heading2 content="Fly-on-the-Wall Observation & Contextual Inquiries"/>
    <div class="wall-content">
      <div class="child-center">
        <div><p class="pf">
          I went to China Film Archive before a screening of The Shining (1980) and a screening of chinese opera film festival.
        </p>
          <p class="pf">
            It's rare to see other cinemas so crowded. Another trait I noticed was that there were more audience came to watch the film along than other cinemas. I asked some
            audience why so many of them came to the watch this film alone. The answers include: it's hard to plan with friends in advance because they bough the teickts so early; or
            they prefer to watch films they like quietly, they think going along has a more immersive experience.
          </p>
          <p class="pf">
            Interestingly, I saw a few people were standing at the lounge, either holding a cardboard or asking around to see if other people are willing to resell their tickets.
          </p>
        </div>
      </div>
      <div>
        <img src="https://storage.googleapis.com/junqi-portfolio/cineex/observation.png" />
      </div>
    </div>
    <Heading2 content="Survey" />
    <p class="pf">
      I conducted a survey to understand the general public's opinion towards current ticket refund and reschedule-related
      issues as well as their needs for film ticket resale.
    </p>
    <Heading3 content="What do you think of the ticket refund and ticket policy of Taopiaopiao and Maoyan?"/>
    <div class="survey-column">
      <div class="one-row">
        <v-chart class="chart" :option = "opt1" ></v-chart>
      </div>
      <div class="one-row">
        <p class="pf">
          It can be seen that the ticket refund or reschedule policies can meet the demands of most people. They don't have trouble
          changing or canceling their tickets. As you buy more tickets, you get a higher membership level, so you are able to cancel
          more tickets and get a refund.
        </p>
      </div>
    </div>
    <Heading3 content="Have you bought resold tickets, if you have, where did you buy them from?"/>
    <div class="survey-column">
      <div class="one-row">
        <v-chart class="chart" :option = "opt2" ></v-chart>
      </div>
      <div class="one-row">
        <p class="pf">
          I asked a follow-up question for people who chose "friends or acquaintances," which was "how did you buy tickets from
          friends or acquaintance?"About 85% of them answered that they happened to see it on their friends' social networks,
          while others say their friends asked them directly if they wanted the tickets.
        </p>
      </div>
    </div>
    <Heading3 content="Which channel of communication would you most prefer if you were to but a ticket?"/>
    <div class="survey-column">
      <div class="one-row">
        <v-chart class="chart" :option = "opt3" ></v-chart>
      </div>
      <div class="one-row">
        <p class="pf">
          Most of the respondents choose instant messages as the most preferred way to communicate. After I analyzed some resale
          platforms on the market, all of them incorporated a built-in instant message feature. I decided to build a built-in
          instant message function.
        </p>
      </div>
    </div>
    <Heading2 content="Findings" />
    <p class="pf mb-80">
      After evaluating the results from the above research, I decided the platform to connect movie audiences should focus
      on reselling movie tickets targeting cinephiles. To be more specific, the target users are frequent arthouse moviegoers.
    </p>
    <Heading2 content="Persona Development" />
    <p class="pf">
      Based on the research insights, I developed a primary persona demonstrating target users, especially their goals and pain points.
    </p>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/persona.png" style="width:60%;" class="br-5 dsd mb-80" alt=""/>
    <Heading2 content="User Journey"/>
    <img src="../assets/cine/cine-journey-map.svg" style="width:90%;" class="dsd mb-50" alt="Journey Map" />
    <p class="pf">
      The overall movie-going experience is frustrating for cinephiles. There are multiple touch points I can work on. The waiting process
      and the process of managing future schedule brought a lot of uncertainties to the users.
    </p>
    <Heading1 content="Product Structure"/>
    <p class="pf">
      Based on the user group and opportunity areas I summarized, I defined CineEx’s product structure. Users can view resale
      tickets by different categories on the ticket screen; they could publish movie tickets by filling a form, scanning the
      physical ticket, or share the electronic ticket to the app.
    </p>
    <img src="../assets/cine/cine-flowchart.svg" style="width:50%;" alt="" class="mt-30"/>
    <Heading1 content="Visual Design"/>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/visual-source.png" alt="" style="width:80%;" class="mb-20"/>
    <Heading2 content="Colors" />
    <div class="colors">
      <div class="color">
        <div class="bg red"></div>
        <div class="name">Main</div>
        <div class="hex">#FF473A</div>
        <div class="rgba">rgba(225, 71, 58, 1)</div>
      </div>
      <div class="color">
        <div class="bg pink"></div>
        <div class="name">Light</div>
        <div class="hex">#F7E9E7</div>
        <div class="rgba">rgba(247, 233, 231, 1)</div>
      </div>
      <div class="color">
        <div class="bg black"></div>
        <div class="name">Black</div>
        <div class="hex">#2D2D2D</div>
        <div class="rgba">rgba(45, 45, 45, 1)</div>
      </div>
      <div class="color">
      <div class="bg grey"></div>
        <div class="name">Light-Black</div>
      <div class="hex">#8F8F8F</div>
      <div class="rgba">rgba(143, 143, 143, 1)</div>
      </div>
      <div class="color">
        <div class="bg white"></div>
        <div class="name">Background</div>
        <div class="hex">#FFFFFF</div>
        <div class="rgba">rgba(249, 249, 249, 1)</div>
      </div>
      <div class="color">
        <div class="bg emphasis"></div>
        <div class="name">Background-Emphasis</div>
        <div class="hex">#F9F9F9</div>
        <div class="rgba">rgba(249, 249, 249, 1)</div>
      </div>
    </div>
    <Heading2 content="Typography" />
    <table class="typography">
      <tr class="title">
        <td>Title</td>
        <td>36 pt</td>
      </tr>
      <tr class="h1">
        <td>Heading 1</td>
        <td>30 pt</td>
      </tr>
      <tr class="h2">
        <td>Heading 2</td>
        <td>24 pt</td>
      </tr>
      <tr class="tx">
        <td>Text</td>
        <td>18 pt</td>
      </tr>
      <tr class="cap">
        <td>Caption</td>
        <td>12 pt</td>
      </tr>
    </table>
    <Heading2 content="Font Family" />
    <img class="font" src="../assets/cine/fonts.png">
    <Heading2 content="Components" />
    <img src="../assets/cine/components.svg" style="width:60%;" />
    <Heading1 content="High-fi Prototype" num="5"/>
    <Heading2 content="Categorization" />
    <p class="pf">
      Display resell ticket in three methods of categorization
    </p>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/categorization.png" style="width:75%;" alt="" class="mt-20"/>
    <Heading2 content="Detail" />
    <p class="pf">
      The ticket details are displayed in a diagram of the seats in that theater. Users can see other resale
      tickets at the same time and location.
    </p>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/detail.png" alt="" style="width:21%;"/>
    <Heading2 content="Message" />
    <p class="pf">
      This part contains notifications of followed films and cinema and instant messages sent by buyers and sellers.
    </p>
    <p class="pf">
      Receive notification when tickets of followed movie has been posted.
    </p>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/messages.png" alt="" style="width:72%;"/>
    <Heading2 content="Me" />
    <p class="pf">
      Me page contains followed films and cinemas and displays published tickets.
    </p>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/account.png" alt="" style="width:50%;" class="mt-20"/>
    <Heading2 content="Publish" />
    <p class="pf">
      Publish a ticket. Besides filling out the form, you can share the ticket information from the ticket booking app to
      this app to import information automatically, or you can import from a screenshot.
    </p>
    <img src="https://storage.googleapis.com/junqi-portfolio/cineex/post.png" alt="" style="width:21%;" class="mt-20"/>
    <Heading1 content="Development" num="6" />
    <heading2 content="Tech Stack" />
    <ul class="ul">
      <li>React Native</li>
      <li>Oracle</li>
      <li>MyBatis</li>
      <li>Spring</li>
    </ul>
  </div>
  <Footer page-name="CineEx"/>
</div>
</template>

<script>
import Heading1 from '../components/PrimaryHeading.vue'
import Heading2 from '../components/SecondaryHeading.vue'
import Heading3 from '../components/AssistHeading.vue'
import ProjectIntro from '../components/ProjectIntro'
import Footer from '../components/GlobalFooter.vue'
import { use } from 'echarts/core'
import {CanvasRenderer} from 'echarts/renderers'
import {BarChart, PieChart} from 'echarts/charts'
import {
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PolarComponent,
  VisualMapComponent
} from 'echarts/components'
import VChart from 'vue-echarts'
use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TooltipComponent,
  VisualMapComponent,
  LegendComponent,
  GridComponent,
  PolarComponent
])
export default {
  name: 'film-exchange',
  components: {Footer, ProjectIntro, Heading3, Heading2, Heading1, VChart},
  mounted () {
  },
  data () {
    return {
      intro: {
        name: 'CineEx',
        team: 'Individual',
        img: 'https://storage.googleapis.com/junqi-portfolio/cineex/affiliations.png',
        affiliation: '<span>QUML & BUPT | </span>Final Year Project',
        bg: 'https://storage.googleapis.com/junqi-portfolio/cineex/cineex_cover.png',
        role: 'Interaction design, software implementation, design research',
        duration: 'Nov. 2019 - May. 2020'
      },
      opt_pie: {
        backgroundColor: '#2c343c',
        tooltip: {
          trigger: 'item'
        },
        visualMap: {
          show: false,
          min: 0,
          max: 60,
          inRange: {
            colorLightness: [0.2, 1]
          }
        },
        series: [
          {
            type: 'pie',
            radius: '72%',
            center: ['53%', '50%'],
            data: [
              { value: 42.6, name: 'Taopiaopiao' },
              { value: 31.5, name: 'Maoyan' },
              { value: 14.3, name: 'Offline' },
              { value: 11.6, name: 'Other' }
            ].sort(function (a, b) {
              return a.value - b.value
            }),
            roseType: 'radius',
            label: {
              color: 'rgba(255, 255, 255, 0.3)'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.4)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            itemStyle: {
              color: '#c23531',
              shadowBlur: 200,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function () {
              return Math.random() * 200
            }
          }
        ]
      },
      opt_cine: {
        polar: {
          radius: [30, '90%']
        },
        radiusAxis: {
          show: false
        },
        angleAxis: {
          show: false,
          axisTick: {
            show: false
          },
          type: 'category',
          data: ['China Film Archive', 'Beijing France Cultural Center', 'Austrian Classical Art Film Festival', 'Beijing International Film Festival',
            'EU Film Festival', 'Ullens Center for Comtemperary Art', 'Moma Broadway Fim Center', 'Cinker', 'Museum of Chinese Films',
            'fRUITYSPACE', 'Dadi Cinema', 'Tianmu Caiyu Cinema', 'Lumiere Pavilions', 'UME Pavilions', 'Huayi Brothers Cinema', 'Capital Cinema', 'Poly Wanyuan Cinema', 'Palace Cinema'],
          startAngle: 100
        },
        tooltip: {},
        series: {
          type: 'bar',
          data: [12, 45, 18, 90, 32, 28, 28, 10, 14, 14, {
            value: 5,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 8,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 5,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 7,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 4,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 2,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 4,
            itemStyle: {
              color: '#2d2d2d'
            }
          }, {
            value: 7,
            itemStyle: {
              color: '#2d2d2d'
            }
          }],
          coordinateSystem: 'polar',
          itemStyle: {
            color: '#ff473a'
          }
        }
      },
      opt1: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].axisValue + '<br/><span style="font-weight: 600;">' + params[0].data + '%</span>'
          }
        },
        textStyle: {
          fontFamily: 'Poppins'
        },
        grid: {
          left: '20px',
          right: '40px',
          bottom: '20px',
          top: '30px',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            data: ['Far from enough', 'Not enough', 'Almost enough', 'Enough', 'More than enough'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              rotate: 30
            }
          }
        ],
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#888'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: [10.8, 5.2, 11.8, 16.1, 56.1],
            itemStyle: {
              normal: {
                color: '#c93531'
              }
            }
          }
        ]
      },
      opt2: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].axisValue + '<br/><span style="font-weight: 600;">' + params[0].data + '%</span>'
          }
        },
        textStyle: {
          fontFamily: 'Poppins'
        },
        grid: {
          left: '20px',
          right: '40px',
          bottom: '20px',
          top: '30px',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            data: ['Never', 'Other', 'Friends or Acquaintances', 'Social Media', 'Secondhand Platforms'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              rotate: 30
            }
          }
        ],
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#888'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: [16.5, 6.8, 66.1, 21, 16.1],
            itemStyle: {
              normal: {
                color: '#c93531'
              }
            }
          }
        ]
      },
      opt3: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].axisValue + '<br/><span style="font-weight: 600;">' + params[0].data + '%</span>'
          }
        },
        textStyle: {
          fontFamily: 'Poppins'
        },
        grid: {
          left: '20px',
          right: '40px',
          bottom: '20px',
          top: '30px',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            data: ['Other', 'SMS', 'Phone Calls', 'Instant Messages', 'Email'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              rotate: 30
            }
          }
        ],
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#888'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: [0, 5.1, 10.7, 86, 2.2],
            itemStyle: {
              normal: {
                color: '#c93531'
              }
            }
          }
        ]
      },
      cineData: [{
        name: 'China Film Archive',
        time: 30
      }, {
        name: 'Beijing France Cultural Center',
        time: '20-26'
      }, {
        name: 'Beijing International Film Festival',
        time: '36-41'
      }, {
        name: 'EU Film Festival',
        time: '22-30'
      }]
    }
  }
}
</script>

<style lang="scss">
.cine-ex {
  text-align: left;
  .heading1 {
    .name {
      color: #272733;
    }
    .lower-bar {
      background-color: #c4c4c4;
    }
  }
  .wall-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    max-width: 1300px;
    img {
      width: 100%;
      max-width: 600px;
    }
  }
  .policy-tables {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    margin: 30px 0 80px 0;
    max-width: 1200px;
    .one-col {
      table {
        border-collapse: collapse;
        font-size: 16px;
        border-radius: 20px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        th {
          .title {
            position: absolute;
            transform: rotate(-90deg) translateX(-50%) translateY(-200%);
            top: 16%;
            left: 0;
            width: 200px;
            text-align: center;
            vertical-align: middle;
            img {
              width: 30px;
              margin-right: 10px;
            }
            img, div {
              display: inline-block;
              vertical-align: middle;
            }
          }
          &:first-child {
            color: #000000;
          }
          position: relative;
          min-width: 40px;
          background-color: #ffffff;
          color: #ff473a;
          width: max-content;
          font-weight: 600;
          padding: 16px;
        }
        td {
          background-color: #ffffff;
          padding: 16px 20px;
          position: relative;
        }
        tr {
          text-align: left;
          padding: 10px;
          background-color: #f7f7f7;
          border-bottom: #eee solid 1px;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  .polar-chart-grid {
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin-bottom: 100px;
    position: relative;
    .polar-legend {
      text-align: left;
      position: absolute;
      left: 400px;
      top: 420px;
      font-size: 14px;
      z-index: 99;
      font-weight: 600;
      span {
        line-height: 20px;
        vertical-align: middle;
      }
      .bar {
        margin-bottom: 12px;
      }
      .red {
        float: left;
        background-color: #FF473A;
        width: 30px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
      }
      .black {
        float: left;
        background-color: #2d2d2d;
        width: 30px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .polar-chart {
      width: 1500px;
      height: 1500px;
      margin: -400px -750px -600px -660px;
    }
    .child-center {
      padding-top: 190px;
    }
  }
  .chart {
    height: 300px;
    width: 100%;
    background-color: #2c343c;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
    border-radius: 20px;
  }
  .pie-chart {
    height: 400px;
    width: 490px;
    background-color: #2c343c;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
    border-radius: 20px;
    overflow: hidden;
  }
  .reference {
    text-align: left;
    font-size: 10px;
    color: #bbbbbb;
    margin: 60px 20% -50px 10px;
  }
  .survey-column {
    display: grid;
    grid-template-columns: 600px 500px;
    column-gap: 80px;
    margin: 16px 0 60px 0;
    .one-row {
      display: flex;
      align-items: center;
      justify-content: left;
    }
  }
  .regulation {
    width: 160px;
    margin: 20px;
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  }
  .findings-demo {
    display: grid;
    padding: 10px;
    column-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 240px;
    .title {
      background-color: #ffffff;
      border-radius: 20px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      padding: 20px;
      margin: 6px;
      color: #ff473a;
    }
  }
  .colors {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    .color {
      background-color: #ffffff;
      padding: 10px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      overflow: hidden;
      .bg {
        height: 100px;
        margin: -10px -10px 10px -10px;
        &.red {
          background-color: #FF473A;
        }
        &.black {
          background-color: #2d2d2d;
        }
        &.pink {
          background-color: #F7E9E7;
        }
        &.grey {
          background-color: #8F8F8F;
        }
        &.emphasis {
          background-color: #F9F9F9;
        }
        &.white {
           background-color: #FFFFFF;
         }
      }
      .name {
        color: #8F8F8F;
        font-size: 16px;
      }
      .rgba {
        font-size: 16px;
      }
      .hex {
        font-size: 20px;
      }
    }
  }
  .typography {
    font-size: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    tr {
      text-align: left;
      padding: 20px;
      &.title {
        font-size: 36px;
        font-weight: 700;
      }
      &.h1 {
        font-size: 30px;
        font-weight: 700;
      }
      &.h2 {
        font-size: 24px;
        font-weight: 500;
      }
      &.tx {
        font-size: 18px;
      }
      &.cap {
        font-size: 12px;
      }
      td {
        padding: 20px 40px 20px 40px;
      }
    }
  }
  .font {
    width: 300px;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
}
</style>
