<template>
  <div :class="['nav-bar', {'collapsed': isCollapsed}]">
    <button @click="isCollapsed = !isCollapsed" :class="{'collapsed': isCollapsed}">
      <span></span><span></span><span></span>
    </button>
    <router-link to="/" class="home-icon">
    <svg width="2000" height="2000" viewBox="0 0 2000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2000 0H0V2000H2000V0ZM1454.13 234.324L1396.5 246V971H1043V681V651H1013H600.5H570.5V711H600.5H983V971H255L243.301 1028.62L1001.3 1349.62L1043 1367.28V1322V1031H1396.5V1753V1783H1456.5V1753V1031H1745.5H1790.74L1773.13 989.324L1454.13 234.324ZM1700.26 971H1456.5V394.084L1700.26 971ZM402.773 1031H983V1276.72L402.773 1031Z" fill="#1d1d1d"/>
    </svg>
    <span class="my-name">Junqi Wang</span>
    </router-link>
    <div :class="['nav-item', {'collapsed': !isCollapsed}]">
      <NavigationItem name="Work" link="/works" :droplist="projects"/>
      <NavigationItem name="About" link="/about"/>
    </div>
  </div>
</template>
<script>
import NavigationItem from './NavigationItem'
export default {
  name: 'navigation-head',
  components: {NavigationItem},
  data () {
    return {
      isCollapsed: false,
      projects: [
        {
          name: 'NetEase',
          link: './netease'
        },
        {
          name: 'Xiaomi',
          link: './xiaomi'
        },
        {
          name: 'CineEx',
          link: './cine-ex'
        },
        {
          name: 'DiDi',
          link: './didi'
        },
        {
          name: 'Triply',
          link: './triply'
        },
        {
          name: 'Daimler',
          link: './daimler'
        }
      ]
    }
  },
  mounted () {
    document.documentElement.scrollTop = 0
  }
}
</script>
<style lang="scss" src="./Navigation.scss"></style>
