<template>
  <div class="sub-content">
    <div class="sub-item">{{name}}</div>
    <div class="sub-name" v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: 'SubContent',
  props: {
    name: {
      type: String
    },
    content: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .sub-content {
    width: 33.333%;
    text-align: left;
    margin: 20px 0;
    .sub-item {
      font-size: 27px;
      font-weight: 500;
    }
    .sub-name {
      font-size: 16px;
    }
  }
</style>
