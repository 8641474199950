<template>
    <div class="daimler fadeInUp animated">
      <ProjectIntro :intro="intro" />
      <div class="content">
        <p class="pf first">
          I started my internship in Daimler from Jun 2019 to Aug 2019, my primary work there is frontend development. But Daimler incubator
          offers an open environment and encourages everyone to take new challenges. I actively participated and communicated with other disciplines.
        </p>
        <Heading1 content="My Team" />
        <p class="pf">
          Daimler Incubator was founded to reform the business of the old auto giant Daimler, Daimler Incubator shoulders the responsibility
          of finding good ideas inside the whole cooperation as well as incubating new startups.
        </p>
        <Heading1 content="Project Popeye" />
        <div class="content-intro">
          <div class="text-left">
            <p class="pf">
              This is Daimler next-gen Truck Connectivity Platform developed for China market, intended to provide monitoring
              and management service for truck fleet managers. The system provides various data visualization and statistics
              for fleet managers to monitor and evaluate their drivers' performances.
            </p>
          </div>
          <div class="text-right">
            <div class="sub-title">Scope</div>
            <div class="sub-content">Full Stack Development</div>
            <div class="sub-title">Technologies</div>
            <div class="sub-content">Vue.js, WebSocket, AMap, ECharts, BootstrapVue</div>
          </div>
        </div>
        <div class="content-intro">
          <div class="detail-title"></div>
        </div>
        <img src="https://storage.googleapis.com/junqi-portfolio/daimler/popeye.png" alt="popeye" style="width:68%;" class="first"/>
        <Heading1 content="Project Awake" num="3" />
        <div class="content-intro">
          <div class="text-left">
            <p class="pf">
              Project Awake is a Daimler scrapped auto parts redesign project, which transfers scrapped auto parts in transportation
              to new products.
            </p>
            <p class="pf">
              I was responsible for the development of the whole site for the display of these products. As the site feature
              is relatively simple, to minimize the cost, I decided to adopt a serverless approach to develop this site.
              All resources, including the UX copywriting, pictures, and frontend pages, are stores in Ali cloud storage
              instead of a server. All updates of products and other information are done by updating files instead of manipulating
              a database.
            </p>
          </div>
          <div class="text-right">
            <div class="sub-title">Scope</div>
            <div class="sub-content">Frontend Development, UX Writing</div>
            <div class="sub-title">Technologies</div>
            <div class="sub-content">Vue.js</div>
          </div>
        </div>
        <img src="https://storage.googleapis.com/junqi-portfolio/daimler/awake.png" style="width:70%;" alt="Awake" class="first" />
      </div>
      <Footer page-name="Daimler"/>
    </div>
</template>

<script>
import ProjectIntro from '../components/ProjectIntro'
import Heading1 from '../components/PrimaryHeading.vue'
import Footer from '../components/GlobalFooter.vue'
import {useMeta} from "vue-meta";
export default {
  name: 'daimler-page',
  components: {Footer, Heading1, ProjectIntro},
  setup () {
    useMeta({
      title: 'Daimler',
      description : "Junqi was a software engineer for Daimler's truck connectivity platform",
      twitter: {
        title: "Daimler | Junqi Wang",
        description: "Junqi was a software engineer for Daimler's truck connectivity platform",
        card: "twitter card",
        image: "https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg",
      },
      og: {
        title : 'Daimler',
        description : 'Junqi was a software engineer for Daimler\'s truck connectivity platform',
        type : 'website',
        url : 'https://junqi.info/daimler',
        image : 'https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg',
        site_name : 'Junqi Wang',
      }})
  },
  data () {
    return {
      intro: {
        name: 'Project Popeye & Project Awake',
        affiliation: '<span>Daimler AG </span>',
        team: 'Lab1886 & Daimler Truck & Mitsubishi Fuso Truck',
        role: 'Software implementation',
        img: 'https://storage.googleapis.com/junqi-portfolio/daimler/daimler_logo.png',
        bg: 'https://storage.googleapis.com/junqi-portfolio/daimler/daimler_cover.png',
        detail: '',
        duration: 'May. 2019 - Sep. 2019'
      },
      initialImg: require('../assets/initial sketch.png')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.daimler {

  .team-pic {
    height: 220px;
    padding: 10px;
  }
  .daimler-intro {
    display: flex;
  }
  .content-intro {
    display: flex;
  }
  .text-left {
    display: inline-block;
    width: 55%;
    text-align: left;
    font-size: 15px;
  }
  .text-right {
    width: 30%;
    padding-left: 5%;
    display: inline-block;
    text-align: left;
    font-size: 24px;
    .sub-content {
      font-size: 16px;
    }
  }
  .detail-title {
    text-align: left;
    font-size: 24px;
    padding-left: calc(5%*0.55);
  }
  .heading1 {
    .num {
      background-color: #04A7EB;
    }
    .lower-bar {
      color: #ffe500;
    }
  }
}
</style>
