<template>
    <div class="footer">
      <div class="inner">
        <router-link to="./"><img alt="Junqi Wang" src="@/assets/logo.svg"/></router-link>
        <div>
          <p>If any of my work interests you, don't hesitate to reach out and connect with me!</p>
          <div class="footer-icon">
            <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" @click="iconRedirect(0)" @mouseover="iconHover(0)" @mouseleave="iconLeave(0)">
              <path d="M100.052 187.416C148.338 187.416 187.469 148.275 187.469 99.9975C187.469 51.722 148.338 12.5837 100.052 12.5837C51.772 12.5837 12.6364 51.722 12.6364 99.9975C12.6364 148.275 51.7718 187.416 100.052 187.416Z" :fill="iconColor.mail"/>
              <path d="M132 68H68C63.58 68 60.04 71.58 60.04 76L60 124C60 128.42 63.58 132 68 132H132C136.42 132 140 128.42 140 124V76C140 71.58 136.42 68 132 68ZM132 82.12C132 83.2895 131.398 84.3766 130.406 84.9964L110.6 97.375C104.115 101.428 95.8854 101.428 89.4 97.375L69.5942 84.9964C68.6025 84.3766 68 83.2895 68 82.12C68 79.4558 70.9305 77.8316 73.1897 79.2436L91.767 90.8544C96.8042 94.0026 103.196 94.0026 108.233 90.8544L126.81 79.2436C129.069 77.8316 132 79.4558 132 82.12Z" fill="white"/>
            </svg>
            <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" @click="iconRedirect(1)" @mouseover="iconHover(1)" @mouseleave="iconLeave(1)">
              <path d="M100.052 187.416C148.338 187.416 187.469 148.275 187.469 99.9976C187.469 51.722 148.338 12.5838 100.052 12.5838C51.772 12.5838 12.6364 51.722 12.6364 99.9976C12.6364 148.275 51.7718 187.416 100.052 187.416" :fill="iconColor.linkedin"/>
              <path d="M75.5886 136.56H58.5477V82.0312H75.5886V136.56ZM66.6314 75.2097H66.5086C60.3425 75.2097 56.3441 71.0414 56.3441 65.7608C56.3441 60.3754 60.4627 56.2922 66.7515 56.2922C73.0358 56.2922 76.903 60.365 77.0264 65.7458C77.0266 71.0284 73.036 75.2097 66.6314 75.2097V75.2097ZM143.76 136.56H124.439V108.342C124.439 100.956 121.42 95.9166 114.771 95.9166C109.687 95.9166 106.863 99.317 105.546 102.597C105.054 103.772 105.129 105.411 105.129 107.054V136.56H85.9884C85.9884 136.56 86.2372 86.5754 85.9884 82.031H105.129V90.5886C106.261 86.8468 112.375 81.5062 122.14 81.5062C134.246 81.5062 143.76 89.3515 143.76 106.239V136.56Z" fill="white"/>
            </svg>
          </div>
          <div class="projects">
            <router-link v-for="(p, i) in projects" :key="i" :to="p.link">{{p.name}}</router-link>
          </div>
        </div>
        <div class="copy">&copy; 2023 Junqi Wang</div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'global-footer',
  data () {
    return {
      projects: [
        {
          name: 'NetEase Internship',
          link: './netease'
        },
        {
          name: 'Xiaomi Internship',
          link: './xiaomi'
        },
        {
          name: 'CineEx',
          link: './cine-ex'
        },
        {
          name: 'DiDi Internship',
          link: './didi'
        },
        {
          name: 'Triply',
          link: './triply'
        },
        {
          name: 'Daimler Internship',
          link: './daimler'
        }
      ],
      iconColor: {
        mail: '#000000',
        linkedin: '#000000'
      }
    }
  },
  props: {
    pageName: {
      type: String,
      required: true
    }
  },
  methods: {
    scroll () {
      const elOffsetTop = document.documentElement.offsetTop
      const docScrollTop = window.scrollTop - 100
      if (elOffsetTop >= docScrollTop && elOffsetTop < (docScrollTop + window.innerHeight)) {
        document.documentElement.removeEventListener('scroll', this.scroll, false)
      }
    },
    iconLeave () {
      this.iconColor.mail = '#000000'
      this.iconColor.linkedin = '#000000'
    },
    iconHover (option) {
      switch (option) {
        case 0:
          this.iconColor.mail = '#cccccc'
          break
        case 1:
          this.iconColor.linkedin = '#cccccc'
          break
      }
    },
    iconRedirect (option) {
      switch (option) {
        case 0:
          window.open('mailto:juqwang@gmail.com')
          this.iconColor.mail = '#cccccc'
          break
        case 1:
          window.open('https://www.linkedin.com/in/jqwang/')
          this.iconColor.linkedin = '#cccccc'
          break
      }
    }},
  mounted () {
    window.addEventListener('scroll', this.scroll)
  }
}
</script>

<style lang="scss">
  @import "../styles/variables";
.footer {
  margin-top: 100px;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.02);
  height: max-content;
  padding: 80px 0 40px 0;
  color: #000000;
  text-align: center;
  .footer-icon {
    text-align: left;
    svg {
      height: 40px;
      width: 40px;
      cursor: url("@/assets/cursor-1.svg") 20 20, auto!important;
    }
  }
  img {
  }
  .copy {
    font-size: 16px;
    padding: 80px 0 10px 0;
    grid-column: 1/3;
  }
  .projects {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    font-size: 16px;
    min-height: 30px;
    text-align: left;
    column-gap: 30px;
    margin: 40px 0 0 0;
    a {
      position: relative;
      text-align: left;
      display: inline-block;
      color: #000000;
      text-decoration: none;
      border-bottom: #1d1d1d 3px solid;
      line-height: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 0;
        height: 100%;
        border-bottom: #cccccc 3px solid;
        transition: 0.2s all linear;
      }
      &:hover::before {
        left: 0;
        width: 100%;
      }
      &:hover ~ &::before {
        left: 0;
      }
      &.router-link-active {
        color: #aaaaaa;
        border-bottom: #aaaaaa 3px solid;
      }
    }
  }
}
  @media(min-width: $md) {
    .footer {
      .footer-icon {
        text-align: left;
        margin: 40px 0 0 0;
        width: auto;
        svg {
          height: 40px;
          width: 40px;
        }
      }
      img {
        transform: translateY(-50%);
        position: relative;
        top: 50%;
        width: 100%;
        margin-left: 0;
      }
      .inner {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-template-rows: max-content max-content;
        column-gap: 100px;
        min-height: 0;
        margin: 0 80px;
        @media(min-width: $lg) {
          margin: 0 auto;
          width: $ls;
        }
        p {
          font-size: 40px;
          text-align: left;
        }
      }
      .projects {
        a {
        }
      }
    }
  }
</style>
