export const data = {
    titles: ['a product manager.', 'an innovator.', 'a software engineer.', 'a UX designer.', 'an explorer.', 'a tech enthusiast.', 'a program manager.', 'a maker.'],
    dev: false,
    all: true,
    des: false,
    pm: false,
    items: [{
        id: '2',
        affiliation: 'NetEase | <span>NetEase News App</span> ',
        name: 'News Customizable Timeline',
        url: '/netease',
        pic: 'https://storage.googleapis.com/junqi-portfolio/netease/logo.png',
        tags: ['InteractionDesign', 'ConsumerProduct', 'NewsApp', 'SocialMedia'],
        detail: 'A customizable news feed'
    }, {
        id: '1',
        affiliation: 'Xiaomi Technology | <span>Voice Assistant</span>',
        name: 'Xiaoai Game Mode',
        url: '/xiaomi',
        pic: 'https://storage.googleapis.com/junqi-portfolio/xiaomi/xiaomi.png',
        tags: ['VoiceAssistant', 'ProductManagement', 'ConsumerProduct', 'ArtificialIntelligence', 'SmartPhone'],
        detail: 'Xiaomi\'s voice assistant\'s gaming scenario optimization'
    }, {
        id: '4',
        affiliation: 'BUPT & QMUL | <span>Final Year Project</span> ',
        name: 'CineEx',
        url: '/cine-ex',
        pic: 'https://storage.googleapis.com/junqi-portfolio/cineex/cineex-cover-thumbnail.png',
        tags: ['InteractionDesign', 'ConsumerProduct', 'SoftwareImplementation', 'CollegeFinalYearProject', 'Mobile'],
        detail: 'A local movie ticket resell app'
    }, {
        id: '5',
        affiliation: 'DiDi | <span>CRM</span>',
        name: 'Contact Reason Configuration Restructure',
        url: '/didi',
        pic: 'https://storage.googleapis.com/junqi-portfolio/home/DiDi.jpg',
        tags: ['ProductManagement', 'InternalBusinessSolution', 'RideHailing', 'Desktop'],
        detail: 'A feature to boost customer service agents\'s productivity in DiDi Global CRM'
    }, {
        id: '3',
        affiliation: 'Individual',
        name: 'Triply',
        url: '/triply',
        pic: 'https://storage.googleapis.com/junqi-portfolio/home/triply-cover.png',
        tags: ['InteractionDesign', 'ConsumerProduct', 'Mobile'],
        detail: 'A component-based collaborative itinerary management app'
    }, {
        id: '6',
        affiliation: 'Daimler AG',
        name: 'Project Popeye & Project Awake',
        url: '/daimler',
        pic: 'https://storage.googleapis.com/junqi-portfolio/daimler/daimler-thumb.png',
        tags: ['SoftwareDevelopment', 'ConsumerProduct', 'BusinessProduct', 'NewProductIncubation', 'Automobile', 'Desktop'],
        detail: 'Daimler truck connectivity platform. <br/>Daimler scraped auto parts reuse program'
    }]
}