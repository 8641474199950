<template>
    <div class="tiktok fadeInUp animated">
      <ProjectIntro :intro="intro" />
      <div class="content">
        <p class="pf first">
          The parent company of TikTok, ByteDance, has a huge product portfolio, 100+ of them spanning across hundreds of countries.
          As a fast growing company, some of its early products or acquired products have not been implemented with Security Development Lifecycle (SDLC).
          As a result, these products may have a higher chance of security and privacy risks.
        </p>
        <Heading1 content="My Approach" />
        <div class="guideline-text"><span class="junqi quote"/>&nbsp;&nbsp;Develop a company as a product.</div>
        <p class="pf">
          Yimin Zhang wrote these words when he founded ByteDance. He never explained it. But the development of ByteDance over the ten years showed how a company can be developed in
          a product design/development of way. Inspired by these words and being a student studying product and serivce design, I used this project as an opportunity to apply service
          design thinking to program management.
        </p>
        <div class="guideline-text"><span class="junqi quote"/>&nbsp;&nbsp;Deliver a program as a service.</div>
        <p class="pf">
          For people who are not familiar with design thinking and design practices, design may seem like the appearance or styling of a particular product or outcome.
          However, its meaning goes way beyond that for practitioners of design. While there is no consensus in the definition of waht is service design. The process and toolbox
          of service design is widely accepted.
        </p>
        <Heading1 content="Problem Statement & Current Status" />
        <Heading1 content="Discover" />
        <p class="pf">The GSPR program was started long before I joined the company. In response to some negative media coverage,
          GSO (Global Security Organization) launched this program to review and mitigate security and privacy risks. Currently, it is reviewing two products each bi-month. </p>
        <p class="pf">Though the program has been launched a year ago. The process still isn't streamlined enough with blockers existing both within GSO as well as from other business units.</p>
        <p class="pf">I first complied a stakeholder map based on my understandings of reading onboarding documents.</p>
        <p class="pf">To better dive into problems concerning each stakeholder to further complete the stakeholder map, I scheduled 1:1 calls with points-of-contact from each stakeholder.</p>
        <p class="pf">After interviewing with them, I also did some desk research based on the documentations they provided me to understand the work process on their end. </p>
      </div>
      <Footer page-name=""/>
    </div>
</template>

<script>
import ProjectIntro from '../components/ProjectIntro'
import Heading1 from '../components/PrimaryHeading.vue'
import Footer from '../components/GlobalFooter.vue'
export default {
  name: 'tiktok-page',
  components: {Footer, Heading1, ProjectIntro},
  data () {
    return {
      intro: {
        name: 'Global Security: Product Review',
        affiliation: '<span>TikTok Inc </span>',
        team: 'Global Security Organization Shared Business Operations and Portfolio Management Office',
        role: 'Technical Program Manager Intern',
        img: 'https://storage.googleapis.com/junqi-portfolio/tt/tt_logo.png',
        bg: 'https://storage.googleapis.com/junqi-portfolio/tt/tt_cover.png',
        detail: '',
        duration: 'May. 2022 - Aug. 2022'
      },
      initialImg: require('../assets/initial sketch.png')
    }
  }
}
</script>

<style lang="scss">
.tiktok {
  .content-intro {
    display: flex;
  }
  .guideline-text {
    text-align: left;
    span {
      color: #2F57C2;
      font-size: 40px;
    }
    font-weight: 600;
    font-size: 30px;
    margin: 16px 0 40px 20px;
    line-height: 42px;
  }
  .text-left {
    display: inline-block;
    width: 55%;
    text-align: left;
    font-size: 15px;
  }
  .text-right {
    width: 30%;
    padding-left: 5%;
    display: inline-block;
    text-align: left;
    font-size: 24px;
    .sub-content {
      font-size: 16px;
    }
  }
  .detail-title {
    text-align: left;
    font-size: 24px;
    padding-left: calc(5%*0.55);
  }
  .heading1 {
    .num {
      background-color: #04A7EB;
    }
    .lower-bar {
      color: #ffe500;
    }
  }
}
</style>
