<template>
  <div class="scroll-down" @click="scrollTop">
    <span class="junqi arrow-handle"></span>
  </div>
</template>

<script>
export default {
  name: 'ScrollDown',
  methods: {
    scrollTop () {
      let timer = setInterval(function () {
        let scrollTop = document.documentElement.scrollTop
        let ispeed = Math.floor(window.innerHeight / 100)
        if (scrollTop >= window.innerHeight) {
          clearInterval(timer)
        }
        document.documentElement.scrollTop += ispeed
      }, 0)
    }
  }
}
</script>

<style lang="scss">
@import "../styles/variables.scss";
  .scroll-down {
    cursor: $hover;
    & * {
      cursor: $hover;
  }
    display: inline-block;
    color: #000000;
    position: absolute;
    bottom: 14px;
    left: 80px;
    @media(min-width: $lg) {
      left: calc(50% - 700px);
    }
    .scroll-text {
      font-size: 14px;
      width: min-content;
      line-height: 16px;
      margin-bottom: -20px;
      font-weight: 700;
    }
    .arrow-handle {
      font-size: 40px;
    }
  }
</style>
