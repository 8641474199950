<template>
  <div class="fadeInUp animated trip">
    <ProjectIntro :intro="intro"/>
    <div class="content trip">
      <p class="pf first">
        Trip planning often comes with a lot of issues. There are flights, hotels to be booked, activities to be scheduled, a budget
        to look over. With booking information scattered across multiple platforms and travel guides exacted from many websites and books,
        it can be confusing to plan a trip — especially in a remote place.
      </p>
      <p class="pf">
        Triply is an componentized app that allows you to <span class="highlight"> collect trip information</span>, <span class="highlight">
        make trip plans</span>, <span class="highlight">share trips</span> and <span class="highlight">check to trips</span>
        created by others. It enables you to plan, view and adjust a trip plan conveniently.
      </p>
      <Heading1 content="Research"/>
      <div class="res-overview">
        <div class="col">
          <div class="filtered">
            <img src="https://storage.googleapis.com/junqi-portfolio/triply/comp_view.jpg" alt="" />
          </div>
          <div class="title">Competitive Analysis</div>
        </div>
        <div class="col">
          <div class="filtered">
            <img src="https://storage.googleapis.com/junqi-portfolio/triply/interview.jpg" alt="" />
          </div>
          <div class="title">User Interview</div>
        </div>
        <div class="col">
          <div class="filtered">
            <img src="https://storage.googleapis.com/junqi-portfolio/triply/questionnaire.jpg" alt="" />
          </div>
          <div class="title">Questionnaire</div>
        </div>
      </div>
      <Heading2 content="Current Approach"/>
      <p class="pf">
        Trip planning has been explored by many other products. I studied current trip planning platforms and
        planning part of trip booking platforms that help users to organize their journeys more efficiently and find information
        more conveniently on the market.
      </p>
      <div class="competitive-gallery">
        <div class="inner" :style="'position: absolute; left: ' + left">
          <div class="item" v-for="(c, i) in competitors"  :key="i">
            <div class="logo"><img :src="c.img" alt=""/></div>
            <div class="tags"><div v-for="(t, d) in c.tags" :key="d">{{t}}</div></div>
            <div class="intro">{{c.des}}</div>
          </div>
        </div>
      </div>
      <div class="switch-button">
        <div class="left" @click="scrollLeft"><span class="junqi arrow-down"></span></div>
        <div class="right" @click="scrollRight"><span class="junqi arrow-down"></span></div>
      </div>
      <Heading1 content="Findings" num="2"/>
      <Heading2 content="Survey" />
      <Heading3 content="Mobile First"/>
      <p class="pf">
        Survey (n=87) conducted shows when users will interact with the app. Users interact with this app in different scenarios,
        to facilitate
      </p>
      <v-chart class="chart1" :option = "opt_format" ></v-chart>
      <Heading3 content="Light Cooperative Editing" />
      <div class="survey-column">
        <div class="one-row">
          <v-chart class="chart" :option = "opt_num" ></v-chart>
        </div>
        <div class="one-row">
          <div>
            <p class="pf">
              To understand people's need to work cooperatively while planning a trip, I asked users about the number of people
              that usually participate in trip planning.
            </p>
            <p class="pf">
              Most of time, trip planning only includes more than 3 people, thus, a light cooperative editing
              should be suffice.
            </p>
          </div>
        </div>
      </div>
      <Heading3 content="What are users most concerned with "/>
      <p class="pf">I asked users in the survey about the current problems they have had during their trips. </p>
      <v-chart class="chart2" :option = "opt_p" ></v-chart>
      <Heading2 content="User Interview" />
      <p class="pf">
        I interviewed seven people of different backgrounds to understand their experience with travel as well as on trip
        planning. Based on the data I gathered during user interviews, four types of user personas are produced to summarize my findings.
      </p>
      <img class="persona" alt="persona" src="../assets/triply/persona.svg"/>
      <p class="pf">
        I learned that users cared about efficiency and collaborativeness. And they value manage an itinerary based their existing social connections.
      </p>
      <Heading1 content="Original Concept"/>
      <span class="idea">Plan + Publish</span>
      <ul class="ul">
        <li>Add, share, publish and collaborate on the trips</li>
        <li>Find trips published by others in a public place</li>
        <li>Make copies of other trips to the user's own space</li>
        <li>Multiple options of notification and reminders</li>
        <li>Collaborative editing notice in realtime to avoid conflicts</li>
        <li>A public place for users to look up others' itineraries for ideas</li>
      </ul>
      <p class="pf mt-20">
        I drew a simple draft and a flow chart to help to map things out.
      </p>
      <img alt="sketch" class="sketch" :src="initialImg"/>
      <img alt="process" class="process-sketch" src="../assets/triply/process-sketch.svg"/>
      <Heading1 content="User Testing" num="4"/>
      <p class="pf">
        I asked a few people about the initial design.
      </p>
      <p class="pf">
        Some of them worried about the collaborative editing stability in regions that do not have a fast <span class="highlight-text">network connection</span>
        and the lack of roaming data when just landing in a new country.
      </p>
      <p class="pf">
        For adding and viewing an itinerary, users feel it is troublesome to do <span class="highlight-text">duplicate work</span>.
      </p>
      <ul class="ul">
        <li>They don't want to <span class="highlight-text">add transportation</span> in the app and then type it in Google Maps again. </li>
        <li>They want to <span class="highlight-text">directly and automatic import</span> from existing booking info. </li>
        <li>They want to find the ongoing trip and the ongoing activity efficiently instead of going through <span class="highlight-text">multiple hierarchies</span> to find them. </li>
      </ul>
      <p class="pf">A paid trip planner said</p>
      <ul class="ul">
        <li>He wants a piece of <span class="highlight-text">personal introduction</span> on the shared itinerary so that people can find him through the itinerary he published.</li>
      </ul>
      <Heading1 content="Refined Concept"/>
      <p class="pf">
        I prepared multiple methods for a user to start a trip and plan:
      </p>
      <ul class="ul">
        <li>Adopt a componentized way to add activities</li>
        <li>Import from collected components</li>
        <li>Create new component templates</li>
        <li>Import from an existing trip of your own</li>
        <li>Import from a published trip</li>
        <li>Duplicate a shared trip via a link or QR code</li>
      </ul>
      <p class="pf">
        I provided multiple options for easy access
      </p>
      <ul class="ul">
        <li>iOS widget</li>
        <li>iOS Shortcut</li>
        <li>Apple Watch app</li>
        <li>A preview for the ongoing trip</li>
      </ul>
      <p class="pf">
        For shared trips, user can view the personal space of the publisher.
      </p>
      <Heading2 content="Wireframe"/>
      <p class="pf">
        After communicating with interviewers for several iterations, a detailed wireframe has been formed to map out ideas.
      </p>
      <img class="wireframe" src="../assets/triply/wireframe.svg"/>
      <Heading1 content="Final Crafting" num="6"/>
      <p class="pf">High-Fidelity prototypes are completed based on the previous wireframe.</p>
      <Heading2 content="Itinerary Management" />
      <img src="https://storage.googleapis.com/junqi-portfolio/triply/itinerary%20management.png" style="width:88%;"/>
      <Heading2 content="Easy Access" />
      <img src="https://storage.googleapis.com/junqi-portfolio/triply/Quick%20Acess.png" style="width:75%;"/>
      <Heading2 content="Component Management" />
      <p class="pf">
        Triply provides componentized methods to manage itineraries, these components can be configured and added.
      </p>
      <img src="https://storage.googleapis.com/junqi-portfolio/triply/Component Management.png" alt="components" style="width:73%;" />
      <Heading2 content="Publishing Content" />
      <img alt="Published Content" src="https://storage.googleapis.com/junqi-portfolio/triply/Published%20Content.png" style="width:81%;" />
      <Heading2 content="Collaborative Editing & Share" />
      <img src="https://storage.googleapis.com/junqi-portfolio/triply/Collaborative.png" alt="Collaboration" style="width:90%;">
      <Heading2 content="Alerts and Notifications" />
      <img src="https://storage.googleapis.com/junqi-portfolio/triply/alert.png" alt="alert" style="width:39%;" class="mt-20 mb-20"/>
      <Heading1 content="Reflection"  num="7"/>
      <Heading2 content="Privacy" />
      <p class="pf">When users are sharing their itineraries, these itineraries may reveal the exact activities they were doing at the time. </p>
      <p class="pf">
        Users may upload sensitive personal information as attachments, e.g., passport photos, credit card photos, and medical information. Sharing
        an itinerary may make such information public by accident.
      </p>
      <Heading2 content="Network Connection" />
      <p class="pf">In less developed or remote regions, the network may not be ideal for online cooperative editing.</p>
    </div>
    <Footer page-name="Triply"/>
  </div>
</template>
<script>
import Heading1 from '../components/PrimaryHeading.vue'
import ProjectIntro from '../components/ProjectIntro'
import Heading2 from '../components/SecondaryHeading.vue'
import Heading3 from '../components/AssistHeading.vue'
import Footer from '../components/GlobalFooter.vue'
import { use } from 'echarts/core'
import {CanvasRenderer} from 'echarts/renderers'
import {BarChart} from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PolarComponent
} from 'echarts/components'
import VChart from 'vue-echarts'
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PolarComponent
])
export default {
  name: 'triply-page',
  components: {Footer, Heading2, ProjectIntro, Heading1, Heading3, VChart},
  data () {
    return {
      intro: {
        name: 'Triply',
        img: require('@/assets/logo.svg'),
        affiliation: 'Individual Project',
        role: 'Interaction design, design research, visual design',
        bg: 'https://storage.googleapis.com/junqi-portfolio/triply/triply_cover.png',
        detail: '',
        team: 'Individual',
        duration: 'May. 2019 - Sep. 2019'
      },
      left: '0px',
      competitors: [{
        img: require('../assets/triply/travefy.svg'),
        tags: ['Professional Only', 'Only Editable on PC', 'Categorization'],
        des: 'Travefy is a professional itinerary planning tool. Itinerary editing is only available on its desktop site, while its mobile app only allows users to view planned itineraries. It serves as a business tool for travel agencies to share itineraries with clients. Travefy displays plans of itineraries by category. It is mainly used to display information provided by the agency to its clients.'
      }, {
        img: require('../assets/triply/google.svg'),
        tags: ['Mail Import', 'Suggestion', 'Recommendation'],
        des: 'Google Trips is more of a booking platform, but it integrates the planning with booking. It provides a trip planning feature, itinerary recommendations, and travel articles. '
      }, {
        img: require('../assets/triply/tripit.svg'),
        tags: ['Business Trips', 'Timeline'],
        des: 'Tripit is a trip planning app. Itineraries are organized in a chronical manner — very similar to a calendar app. Each itinerary is a type defined in the app. Each type provides detailed input fields that you can specify. \n' +
          'Tripit provides notifications for upcoming plans. \n' +
          'Plans can be displayed on a map. \n'
      }, {
        img: require('../assets/triply/roadtrippers.svg'),
        tags: ['Road Trip', 'Navigation', 'Review', 'Map'],
        des: 'Roadtrippers is an app that focuses on road trip itineraries. The features it provides include navigation, itinerary recommendation, and finding room and board along the way. \n' +
          'Itineraries are displayed as routes in maps. \n' +
          'Trip guides consist of detailed copy and a trip that can be copied. '
      }, {
        img: require('../assets/triply/pack.png'),
        tags: ['Automation', 'Packing List'],
        des: 'PackPoint is a packing app that automatically generates your packing list based on activities and the weather of the destinations you choose. \n' +
          'You can also customize your activities and the packing list of that activity. '
      }],
      opt_format: {
        title: [
          {
            subtext: 'In Transit',
            left: '10%',
            top: '85%',
            textAlign: 'center'
          },
          {
            subtext: 'Walking',
            left: '30%',
            top: '85%',
            textAlign: 'center'
          },
          {
            subtext: 'At Residence',
            left: '55%',
            top: '85%',
            textAlign: 'center'
          },
          {
            subtext: 'During Entertainment',
            left: '84%',
            top: '85%',
            textAlign: 'center'
          }
        ],
        legend: {
          left: 10,
          data: ['Before a Trip', 'During a Trip']
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          }
        },
        textStyle: {
          fontFamily: 'Poppins'
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            name: 'In Transit',
            label: {
              show: false
            },
            type: 'pie',
            radius: '40%',
            center: ['10%', '50%'],
            data: [{value: 34, name: 'During a Trip', itemStyle: {color: '#6336ff'}}, {value: 7, name: 'Before a Trip', itemStyle: {color: '#ffe92c'}}]
          }, {
            name: 'Walking',
            label: {
              show: false
            },
            radius: '15%',
            center: ['30%', '50%'],
            type: 'pie',
            data: [{value: 11, name: 'During a Trip', itemStyle: {color: '#6336ff'}}, {value: 4, name: 'Before a Trip', itemStyle: {color: '#ffe92c'}}]
          }, {
            name: 'At Residence',
            label: {
              show: false
            },
            radius: '65%',
            center: ['55%', '50%'],
            type: 'pie',
            data: [{value: 11, name: 'During a Trip', itemStyle: {color: '#6336ff'}}, {value: 4, name: 'Before a Trip', itemStyle: {color: '#ffe92c'}}]
          }, {
            name: 'During Entertainment',
            label: {
              show: false
            },
            radius: '73.6%',
            center: ['85%', '50%'],
            type: 'pie',
            data: [{value: 63, name: 'During a Trip', itemStyle: {color: '#6336ff'}}, {value: 11, name: 'Before a Trip', itemStyle: {color: '#ffe92c'}}]
          }
        ]
      },
      opt_num: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        textStyle: {
          fontFamily: 'Poppins'
        },
        grid: {
          top: 0,
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            data: ['6+', '4-5', '2-3', '1'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: [5.8, 18.4, 59.8, 16.1],
            itemStyle: {
              normal: {
                color: '#6336ff'
              }
            }
          }
        ]
      },
      opt_p: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        textStyle: {
          fontFamily: 'Poppins'
        },
        grid: {
          top: 0,
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            data: ['Forgot/missed booked things', 'Forgot to bring things', 'Schedule planing', 'Over budget',
              'Hard to collect information on various platforms', 'Unfamiliar with local events and special activities', 'Local Traffic'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: [31.5, 29.9, 41.4, 39.1, 39.1, 62.1, 65.1],
            itemStyle: {
              normal: {
                color: '#6336ff'
              }
            }
          }
        ]
      },
      initialImg: require('../assets/initial sketch.png')
    }
  },
  methods: {
    scrollRight () {
      let left = 0
      if (this.left === '0px') {
        let timer = setInterval(function () {
          let ispeed = -10
          left += ispeed
          this.left = left + 'px'
          if (left === -1290) {
            clearInterval(timer)
          }
        }.bind(this), 0)
      }
    },
    scrollLeft () {
      let left = -1290
      if (this.left === '-1290px') {
        let timer = setInterval(function () {
          let ispeed = 10
          left += ispeed
          this.left = left + 'px'
          if (left === 0) {
            clearInterval(timer)
          }
        }.bind(this), 0)
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
@import "../styles/variables";
  .trip {
    .background {
      padding-bottom: 100px;
      margin-bottom: -100px;
      .scroll-down {
        bottom: 114px;}
    }
    .res-overview {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 50px;
      margin: 0 50px;
      .col {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 16px;
        position: relative;
        .filtered {
          background-color: #6336ff;
          width: 100%;
          position: relative;
          margin: 0 auto 60px 0;
          max-height: 300px;
          overflow: hidden;
          img {
            mix-blend-mode: screen;
            filter: grayscale(100%);
            width: 100%;
          }
        }
        .title {
          position: absolute;
          font-size: 24px;
          font-weight: 500;
          bottom: 20px;
          margin: 20px 0 0 0;
          width: calc(100% - 32px);
        }
      }
    }
    .competitive-gallery {
      overflow: hidden;
      margin-top: 40px;
      position: relative;
      height: 540px;
      .inner {
        display: grid;
        grid-template-columns: repeat(5, 400px);
        width: max-content;
        column-gap: 30px;
        .item {
          background-color: #ffffff;
          border-radius: 20px;
          padding: 20px;
          .logo {
            padding: 30px 0;
            img {
              width: 240px;
            }
          }
          .tags {
            text-align: left;
            margin: 20px 0;
            display: flex;
            flex-wrap: wrap;
            div {
              margin: 0 4px 4px 0;
              background-color: #ffe92c;
              color: #1d1d1d;
              width: max-content;
              padding: 4px 10px;
              border-radius: 20px;
              font-size: 14px;
              font-weight: 600;
            }
          }
          .intro {
            text-align: left;
            font-size: 16px;
          }
        }
      }
    }
    .switch-button {
      display: flex;
      width: max-content;
      div {
        width: 50px;
        height: 50px;
        border-radius: 30px;
        background-color: #ffffff;
        cursor: $hover;
        & * {
          cursor: $hover;
        }
        &:hover {
          background-color: #eeeeee;
        }
      }
      .left {
        .junqi {
          display: block;
          transform: rotate(90deg);
        }
        margin-right: 20px;
      }
      .right {
        .junqi {
          display: block;
          transform: rotate(-90deg);
        }
      }
      .junqi {
        font-size: 20px;
        font-weight: 600;
        line-height: 50px;
      }
    }
    .idea {
      font-size: 30px;
      margin: 20px 0;
      color: #6336ff;
    }
    .pain-points {
      width: 80%;
    }
    .heading1 {
      .num {
        background-color: #6336FF;
      }
      .name {
      }
      .lower-bar {
        background-color: #ffe92c;
      }
    }
    .highlight {
      width: max-content;
      display: inline-block;
    }
    .highlight-text {
      color: #6336ff;
      font-weight: 700;
    }
    .process-sketch {
      width: 36%;
    }
    .persona {
      width: 80%;
      margin-top: 40px;
    }
    .sketch {
      width: 30%;
    }

    .chart {
      height: 300px;
      width: 85%;
      margin: 10px auto;
    }

    .chart1 {
      height: 300px;
      width: 100%;
      border-radius: 20px;
      margin: 50px 0;
    }
    .chart2 {
      height: 500px;
      width: 100%;
      border-radius: 20px;
      margin: 50px 0;
    }
    .survey-column {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .one-row {
        display: flex;
        align-items: center;
      }
    }
    .wireframe {
      width: 80%;
      margin: 0 auto;
    }
  }
</style>
