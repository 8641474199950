<template>
  <section class="timeline-item">
    <div class="item">
      <span :style="getBackgroundColour(itemTimeline.color)" class="dot" />
      <span :style="getBackgroundColour(itemTimeline.color)" class="dot animate-dot" />
      <h4 class="title-item" v-html="itemTimeline.title" />
      <div class="position" v-html="itemTimeline.position"></div>
      <ul>
        <li v-for="(d, i) in itemTimeline.description" :key="i" v-html="d" />
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    itemTimeline: {
      type: Object,
      default: () => ({})
    },
    colorDots: {
      type: String,
      default: '#aaaaaa'
    },
    dateLocale: {
      type: String,
      default: ''
    }
  },
  methods: {
    getBackgroundColour (color) {
      return color ? `background:${color}` : `background:${this.colorDots}`
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-item {
  .item {
    border-left: 1.5px solid rgba(29, 29, 29, 0.4);
    padding: 20px 0 20px 25px;
    position: relative;
  }
  .title-item {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
  }
  li {
    font-weight: 200;
    margin: 0 0 6px 16px;
    font-size: 14px;
    list-style: disc;
  }
  img {
    min-width: 80px;
    min-height: 30px;
    max-width: 100px;
    max-height: 60px;
    padding: 0 0 12px 0;
  }
  .dot {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    // background: #2da1bf;
    left: -8px;
    top: 26px;
    &.animate-dot {
      animation: grow 1.5s ease-in-out infinite;
    }
  }
  .position {
    font-size: 22px;
    margin: 0 0 8px 0;
  }
  @keyframes grow {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2.2);
      opacity: 0;
    }
    }
}
</style>
