<template>
  <div class="heading1">
    <div class="name" v-html="content"></div>
    <div class="sub" v-if="sub">{{sub}}</div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    sub: {
      type: String
    }
  }
}
</script>
<style lang="scss">
  .heading1 {
    width: 100%;
    max-width: 800px;
    margin: 120px 0 34px 0;
    text-align: left;
    .name {
      font-size: 42px;
      font-weight: 300;
      .junqi {
        font-size: inherit;
      }
    }
    .sub {
      font-size: 28px;
      font-weight: 500;
      color: #565656;
    }
  }
</style>
