<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Junqi Wang` : `Junqi Wang` }}</template>
  </metainfo>
  <router-view/>
</template>
<script>
import {useMeta} from "vue-meta";

export default {
  name: 'App',
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true },
        description : "Junqi is a product manager with 2+ years of experience and a background in various stages of digital product development process and operation.",
        twitter: {
      title: "Junqi Wang",
          description: "Junqi is a product manager with 2+ years of experience and a background in various stages of digital product development process and operation.",
          card: "twitter card",
          image: "https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg",
    },
    og: {
      title : 'Junqi Wang',
          description : 'Junqi is a product manager with 2+ years of experience and a background in various stages of digital product development process and operation.',
          type : 'website',
          url : 'https://junqi.info/',
          image : 'https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg',
          site_name : 'Junqi Wang',
    }})
  },
  data () {
    return {
      settings: {
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Poppins', "Adobe Clean", 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1d1d1d;
}
.ps__thumb-x, .ps__thumb-y {
  background-color: #FF473A;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100vh;
}
</style>
