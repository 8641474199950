import {createRouter, createWebHistory} from 'vue-router'
import Works from '@/pages/Works.vue'
import About from '@/pages/AboutMe.vue'
import TripHub from '@/pages/Triply.vue'
import DiDi from '@/pages/DiDi.vue'
import FilmExchange from '@/pages/FilmExchange.vue'
import Awake from '@/pages/Daimler.vue'
import Netease from '@/pages/Netease.vue'
import Xiaomi from '@/pages/Xiaomi.vue'
import TikTok from '@/pages/TikTok.vue'


export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            meta: {title: 'Junqi Wang'},
            component: Works
        }, {
            path: '/works',
            name: 'Works',
            meta: {title: 'Works | Junqi Wang'},
            component: Works
        }, {
            path: '/about',
            name: 'About',
            meta: {title: 'About | Junqi Wang'},
            component: About
        }, {
            path: '/triply',
            name: 'Triply',
            meta: {title: 'Junqi Wang | Triply'},
            component: TripHub
        }, {
            path: '/didi',
            name: 'DiDi',
            meta: {title: 'DiDi Internship | Junqi Wang'},
            component: DiDi
        }, {
            path: '/cine-ex',
            name: 'FilmEx',
            meta: {title: 'CineEx | Junqi'},
            component: FilmExchange
        }, {
            path: '/daimler',
            name: 'Daimler',
            meta: {title: 'Daimler Internship | Junqi Wang'},
            component: Awake
        }, {
            path: '/netease',
            name: 'Netease',
            meta: {title: 'NetEase Internship | Junqi Wang'},
            component: Netease
        }, {
            path: '/xiaomi',
            name: 'Xiaomi',
            meta: {title: 'Xiaomi Internship | Junqi Wang'},
            component: Xiaomi
        }, {
            path: '/tiktok',
            name: 'Tiktok',
            meta: {title: 'TikTok Internship | Junqi Wang'},
            component: TikTok
        }
    ]
})
