<template>
  <router-link :to="to" class="widget">
    <div class="status-effect"></div>
    <div :class="[{'animated fadeInUp': isShow}]">
      <div class="intro">
        <div class="affiliation" v-html="affiliation"></div>
        <div class="title">{{title}}</div>
        <div class="tag" v-for="(t, k) in tags" :key="k">#{{t}}</div>
        <div class="detail" v-html="detail"></div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  name: 'widget-com',
  props: {
    wId: {
      type: String
    },
    affiliation: {
      type: String
    },
    to: {
      type: String
    },
    pic: {
      type: String
    },
    title: {
      type: String
    },
    detail: {
      type: String
    },
    tags: {
      type: Array
    }
  },
  data () {
    return {
      isShow: false
    }
  }
}
</script>
<style lang="scss">
  @import "../styles/variables.scss";
  .widget {
    position: relative;
    display: block;
    text-decoration: none !important;
    border-top: #1d1d1d 2px solid;
    height: min-content;
    &::before {
      content: "";
      position: absolute;
      top: calc(-100% - 2px);
      left: 100%;
      width: 0;
      height: 100%;
      border-bottom: #cccccc 2px solid;
      transition: 0.2s all linear;
    }
    &:hover::before {
      left: 0;
      width: 100%;
    }
    &:hover ~ &::before {
      left: 0;
    }
    .affiliation {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      span {
        color: #000000;
        font-weight: 500;
      }
    }
    .widget-cover {
      width: 100%;
      height:562px;
      background-size:cover;
      background-repeat: no-repeat;
      background-position: top center ;
    }
    .intro {
      text-align: left;
      padding: 20px 0 0 0;
      color: #000000;
      width: 80%;
      .title {
        font-size: 36px;
        transition: all 1s;
        line-height: 42px;
        margin: 6px 0 20px 0;
        font-weight: 300;
      }
      .tag {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        padding: 4px 8px 0 4px;
        line-height: 16px;
      }
      .detail {
        margin-top: 20px;
        font-size: 16px;
        opacity: 0.8;
      }
    }
  }
  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes border {
    from {
      border-top: #1d1d1d 2px solid;
    }

    to {
      border-top: #cccccc 2px solid;
    }
  }
  .invisible {
    visibility: hidden;
  }
  @media(min-width: $lg) {
    .widget {
      width: 96%;
      margin: 0;
    }
  }
</style>
