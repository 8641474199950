<template>
    <div class="didi fadeInUp animated">
      <ProjectIntro :intro="intro"/>
      <div class="content">
        <Heading1 content="The Team & The Product"/>
        <p class="pf">
          My team is responsible for DiDi in-app Help Center and Customer Relationship Management platform. In the process, I helped
          to improve its CRM efficiency and usability, to add new features as well as coordinate resources and set strategy for
          products in different markets to assist customer service agents in meeting DiDi's ever-expanding market oversea.
        </p>
        <p class="pf">
          As the world's largest ride-hailing company, DiDi's market used to be only China. By the time I started my internship, DiDi has rapidly
          expanded into oversea markets. In around a year, DiDi IBG expanded from 0 order to over 8 million orders
          per day, IBG itself can be ranked as the world's third-largest ride-hailing platform if it is ranked as an entity alone.
        </p>
        <p class="pf">
          Under this rapid expansion, our software infrastructure is lagging behind but iterating fast.
        </p>
        <Heading2 content="Northstar Metrics"/>
        <p class="pf">
          Similar to many other business products, our product objective is lowering cost, increasing efficiency, and elevate end customers' experience. We have the following metrics to guide our product development.
        </p>
        <div class="icon-demo data-4">
          <div class="col">
            <div class="title">Call per Order (CPO)</div>
          </div>
          <div class="col">
            <div class="title">Average Handling Time (AHT)</div>
          </div>
          <div class="col">
            <div class="title">Customer Satisfaction Score (CSat)</div>
          </div>
          <div class="col">
            <div class="title">Net Promoter Score (NPS)</div>
          </div>
        </div>
        <Heading2 content="Primary Stakeholders"/>
        <p class="pf">
          The primary stakeholders of our product are:
        </p>
        <div class="icon-demo data-6">
          <div class="col">
            <div class="title">Customer Service Agents</div>
          </div>
          <div class="col">
            <div class="title">Customer Service Operation Team</div>
          </div>
          <div class="col">
            <div class="title">DiDi Passengers</div>
          </div>
          <div class="col">
            <div class="title">DiDi Drivers</div>
          </div>
          <div class="col">
            <div class="title">Customer Service Infrastructure Development Team</div>
          </div>
          <div class="col">
            <div class="title">Design Team (PMs and Designers)</div>
          </div>
        </div>
        <p class="pf">
          We usually discover new needs to be addressed through:
        </p>
        <ul class="ul">
          <li>User feedbacks (usually NPS surveys and in-app open-end questions) or user behavior data.</li>
          <li>Ethnographic research (interviews, surveys, fly-on-the-wall observation, contextual inquiries, etc.) with customer service agents.</li>
          <li>Customer service agents' behavior data.</li>
          <li>Feedback from customer service operation teams in different regions.</li>
          <li>Heuristic analysis by product managers or UYX designers.</li>
          <li>Automation abilities of launching new regions or new business lines proposed by the development team.</li>
        </ul>
        <Heading1 content="Context" />
        <Heading2 content="What is the Contact Reason"/>
        <p class="pf">
          A Contact Reason means a categorization of user inquiries. Every ticket inbound or created by customer service agents must have its
          Contact Reason specified. Contact Reasons can be assigned manually by customer service agents, by the FAQ a customer
          chose.
        </p>
        <p class="pf">
          Contact Reasons are created in a tree-like structure. The operation team can create new nodes on each contact reason,
          but only the end node can be selected bv a customer service agent.
        </p>
        <Heading2 content="Current Solution"/>
        <p class="pf">
          Contact reasons are organized in a table. For the operation team, if they want to edit  Contact Reasons, they have to use the table to go through
          column by column without an intuitive mental model whether the Contact Reason has a child node or not, or how many child nodes it has.
        </p>
        <p class="pf">
          The current solution to configure and query Contact Reasons is a set of dropdown fields and a table. Because of
          resource constraints at that time, the feature is an old legacy developed entirely by the development team without any participation
          of product managers nor designers.
        </p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/original-display.png" alt="" style="width:40%;" />
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/original-add.png" alt="" style="width:40%;" />
        <Heading1 content="Problem Statement" />
        <p class="pf">
          Contact Reason selection was chosen as the most difficult feature in a survey distributed to customer service
          agents.
        </p>
        <ul class="ul">
          <li>Difficult to search the CR table. Only one column's field is allowed to fill in content for each search.</li>
          <li>Information structure is not clear; hierarchy of contact reasons are not shown. </li>
          <li>Adding or modifying Contact Reasons is done directly in the table, inconsistent with use interface convention. </li>
          <li>Contact reason selection and configuration has been one of the top complaints in our surveys for customer service agents. </li>
          <li>
            The tickets created by emails sent to our email address directly don't have an assigned Contact Reason because
            they don't go through the CR selection process compared to other channels.
          </li>
          <li>
            DiDi does not provide email customer service in Greater China area, as Chinese aren't used to use email services.
            There is no existing solutions we can borrow from our China operations.
          </li>
        </ul>
        <p class="pf">These problems can lead to difficulty for the operation teams to review the contact reason frequently. If they
          want to compare the relationship between different nodes, they have to memorize it and they go to find other nodes. These problems created a potential
          higher risk to cause Contact Reasons not to be updated frequently and accurately.</p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/survey-1.jpg" alt="" style="width:22%;" />
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/survey-2.jpg" alt="" style="width:22%;" />
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/survey-3.jpg" alt="" style="width:22%;" />
        <Heading1 content="Competitive Analysis" />
        <p class="pf">
          I did a few competitive analysis on our competitors including OlaCab, Uber, Lyft from a consumer's perspective.
        </p>
        <p class="pf">
          It turns out that except OlaCabs, other ride-hailing services usually don't expose their email address to the public.
          They usually provide a form for users to fill in their reasons of contact and other inquiry information, the customer service agents
          will contact them after receiving their response. This solution avoids the problem of end users not being able to select
          contact reasons.
        </p>
        <Heading1 content="Reflection" />
        <p class="pf">
          Since the contact reasons are in a tree-like hierarchy, to minimize the cognitive load of mental model mapping, the ideal
          solution would be presenting the Contact Reasons in a tree-like way.
        </p>
        <p class="pf">
          There are very extensive email customer service infrastructure exist, switching to the form submission method used by Lyft and Uber is not realistic
          at the moment.
        </p>
        <p class="pf">
        For many newly launched regions, updating Contact Reasons can not be done ine a timely manner. Instead of exposing incompleted contact reasons
          to consumers, allowing them to contact human agents directly is a more effective and satisfying way.
      </p>
        <p class="pf">
          Only Contact Reasons of the lowest level can be assigned by CSR agents or configured in the Knowledge Base (FAQ
          and Process Card), so in an email customer email inbound, only the last level of Contact Reasons should be auto-matched.
        </p>
        <Heading1 content="Iterations" />
        <p class="pf">
          In the first iteration, contact reasons are displayed using a collapsible tree-like structure. Only the contact reasons
          for the first two levels are expanded by default. Three tabs are used to choose business type, user type, and status.
        </p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/detail-1.png" alt="" style="width:40%;" />
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/edit-1.png" alt="" style="width:40%;" />
        <p class="pf">
          A challenge is to show the search result, as a tree-like structure cannot display as much information as a table,
          my initial thinking is to use an expandable table after clicking the search field.
        </p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/search-1.png" alt="" style="width:40%;" />
        <p class="pf">
          I decided to use keywords to match the inbounding email content, assign each keyword a weight to represent its
          connection to this Contact Reason. A set of logic is used to determine which Contact Reason should be assigned.
        </p>
        <img src="https://storage.googleapis.com/junqi-portfolio/didi/edit.png" alt="" style="width:30%;"/>
        <img src="https://storage.googleapis.com/junqi-portfolio/didi/cr-flowchart.png" alt="" style="width:30%;"/>
        <p class="pf">
          As Contact Reasons under different business types and user types are similar, it is necessary to bulk copy Contact Reasons.
        </p>
        <p class="pf">
          In the add delete feature, users deleting the Contact Reason can keep or remove its child Contact Reasons.
        </p>
        <img src="https://storage.googleapis.com/junqi-portfolio/didi/cr-copy.png" style="width:20%;"/>
        <img src="https://storage.googleapis.com/junqi-portfolio/didi/cr-delete.png" style="width:20%;" />
        <Heading1 content="Limitations" />
        <ul class="ul">
          <li>The search result is displayed in a table, the connection between the display tree and the table is not clear. </li>
          <li>There is both search filters and three tabs, the function is redundant. </li>
          <li>Only Contact Reasons for one business type and user type can be displayed at the same time, which is inconvenient
            to compare between different user types and business types. </li>
        </ul>
        <p class="pf">
          The second iteration removed the selection tabs. The search result is displayed as highlights in the tree. More details are added.
        </p>
        <p class="pf">
          Add business types and user types as nodes on the tree. This enables the user to compare different business types and user types. .
        </p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/browse-2.png" alt="" style="width:40%;" />
        <p class="pf">
          Move detail from a dialogue box to an expandable side drawer. Two new fields [Description] and [Owner] are added.
        </p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/detail-2.png" alt="" style="width:40%;" />
        <p class="pf">
          The search result is displayed directly on the tree structure. Contact Reasons included in the searched result
          is marked with highlights. Three groups of filters are used.
        </p>
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/search-2.png" alt="" style="width:40%;" />
        <img class="dsd br-5" src="https://storage.googleapis.com/junqi-portfolio/didi/highlight-2.png" alt="" style="width:40%;" />
        <Heading1 content="Final Solution"/>
        <p class="pf">
          After communicating with the UX designer, some modifications are brought forward. As the whole system is lacking
          proper UX design, design team are planning an overhaul to restructure the whole system. But the overhaul takes
          a lot of time. They believe this feature should be consistent with current styles.
        </p>
        <img src="https://storage.googleapis.com/junqi-portfolio/didi/final.png" alt="" style="width:75%;" />
        <Heading1 content="Metrics & the Impact"/>
        <p class="pf">
          Several metrics are used to evaluate the benefits of the project
        </p>
        <ul class="ul">
          <li>Partial release, compare the average duration of time on the page. </li>
          <li>After the partial release, add the old version link in the new version, record the times of accessing the old version. </li>
          <li>Compare the Contact Reason Adherence and the time customer service agents take to select a Contact Reason. </li>
        </ul>
        <Heading1 content="Reflection & Further Planning"/>
        <ul class="ul">
          <li>Collaborate with DiDi AI Labs to develop a Machine Learning Model to auto classify the content sent by users. (Schedule to 2020) </li>
          <li>Another style optimization during the overhaul restructure. </li>
          <li>Provide more forms for users to submit email support request, reduce the use of email address directly </li>
        </ul>
      </div>
      <Footer page-name="DiDi"/>
    </div>
</template>

<script>
import Heading1 from '../components/PrimaryHeading.vue'
import Heading2 from '../components/SecondaryHeading.vue'
import Footer from '../components/GlobalFooter.vue'
import ProjectIntro from '../components/ProjectIntro'
import {useMeta} from "vue-meta";
export default {
  name: 'DiDi-page',
  components: {ProjectIntro, Footer, Heading2, Heading1},
  setup () {
    useMeta({
      title: 'DiDI',
      description : "Junqi was a product manager for DiDi's Global CRM system",
      twitter: {
        title: "DiDi | Junqi Wang",
        description: "Junqi was a product manager for DiDi's Global CRM system",
        card: "twitter card",
        image: "https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg",
      },
      og: {
        title : 'DiDi',
        description : "Junqi was a product manager for DiDi's global CRM",
        type : 'website',
        url : 'https://junqi.info/didi',
        image : 'https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg',
        site_name : 'Junqi Wang',
      }})
  },
  data () {
    return {
      intro: {
        name: 'Contact Reason Configuration Restructure',
        img: require('@/assets/DiDi_logo.svg'),
        affiliation: 'DiDi | <span>CRM</span>',
        bg: 'https://storage.googleapis.com/junqi-portfolio/didi/didi_cover.png',
        team: 'DiDi International Business Technology | Customer Service Product',
        role: 'Product Management Intern',
        duration: 'Aug. 2019 - Dec. 2019'
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
  .didi {
    .didi-intro {
      display: flex;
    }
    .content img {
      margin: 28px;
    }
    .team-pic {
      height: 220px;
      padding: 10px;
    }
    .heading1 {
      .num {
        background-color: #ff7e33;
      }
      .name {
        color: #272733;
      }
      .lower-bar {
        background-color: #3CDBC0;
      }
    }
  }
</style>
