<template>
  <div class="about">
    <Navigation/>
    <div class="content">
      <div class="about-me">About Me</div>
      <div class="about-intro">Hi, I'm <span>Junqi Wang</span>,
      </div>
      <p class="pf">
        a product manager with 3+ years of experience and a background in various stages of the digital product development process and operations.
      </p>
      <p class="pf">
        My passion lies at the intersection between
        humanity and technology and I love building stuff to solve problems. That led me to have academic and professional experience in product management,
        software engineering, UX design, and program management, which has given me solid technical proficiency, communication and collaboration skills, business acumen, as well as a human-centered product design approach.
        It also allows me to better empathize with key stakeholders and customers across the digital product development lifecycle and different customers in the ecosystem. I consider myself an explorer and a maker: I am comfortable with being uncomfortable and eager to get
        familiar with things that I am not familiar with.
      </p>
      <p class="pf">
        I view products and services as tools, better tools that can improve human life as individuals and as a society efficiently and effectively.
      </p>
      <div class="footer-icon">
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" @click="iconRedirect(0)" @mouseover="iconHover(0)" @mouseleave="iconLeave(0)">
          <path d="M100.052 187.416C148.338 187.416 187.469 148.275 187.469 99.9975C187.469 51.722 148.338 12.5837 100.052 12.5837C51.772 12.5837 12.6364 51.722 12.6364 99.9975C12.6364 148.275 51.7718 187.416 100.052 187.416Z" :fill="iconColor.mail"/>
          <path d="M132 68H68C63.58 68 60.04 71.58 60.04 76L60 124C60 128.42 63.58 132 68 132H132C136.42 132 140 128.42 140 124V76C140 71.58 136.42 68 132 68ZM132 82.12C132 83.2895 131.398 84.3766 130.406 84.9964L110.6 97.375C104.115 101.428 95.8854 101.428 89.4 97.375L69.5942 84.9964C68.6025 84.3766 68 83.2895 68 82.12C68 79.4558 70.9305 77.8316 73.1897 79.2436L91.767 90.8544C96.8042 94.0026 103.196 94.0026 108.233 90.8544L126.81 79.2436C129.069 77.8316 132 79.4558 132 82.12Z" fill="white"/>
        </svg>
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" @click="iconRedirect(1)" @mouseover="iconHover(1)" @mouseleave="iconLeave(1)">
          <path d="M100.052 187.416C148.338 187.416 187.469 148.275 187.469 99.9976C187.469 51.722 148.338 12.5838 100.052 12.5838C51.772 12.5838 12.6364 51.722 12.6364 99.9976C12.6364 148.275 51.7718 187.416 100.052 187.416" :fill="iconColor.linkedin"/>
          <path d="M75.5886 136.56H58.5477V82.0312H75.5886V136.56ZM66.6314 75.2097H66.5086C60.3425 75.2097 56.3441 71.0414 56.3441 65.7608C56.3441 60.3754 60.4627 56.2922 66.7515 56.2922C73.0358 56.2922 76.903 60.365 77.0264 65.7458C77.0266 71.0284 73.036 75.2097 66.6314 75.2097V75.2097ZM143.76 136.56H124.439V108.342C124.439 100.956 121.42 95.9166 114.771 95.9166C109.687 95.9166 106.863 99.317 105.546 102.597C105.054 103.772 105.129 105.411 105.129 107.054V136.56H85.9884C85.9884 136.56 86.2372 86.5754 85.9884 82.031H105.129V90.5886C106.261 86.8468 112.375 81.5062 122.14 81.5062C134.246 81.5062 143.76 89.3515 143.76 106.239V136.56Z" fill="white"/>
        </svg>
      </div>
      <Heading1 content="Recently..." />
      <div class="recent">
        <div class="col">
          <div class="title">I've been listening to...</div>
          <img src="https://upload.wikimedia.org/wikipedia/en/4/48/Metric_-_Formentera.png" alt=""/>
        </div>
        <div class="col">
          <div class="title">I've been watching...</div>
          <img src="https://m.media-amazon.com/images/M/MV5BNDk5MDA2YTUtYWMwZi00MDk0LWIwOWYtMTc2ZTVjNDk4MmMxXkEyXkFqcGdeQXVyMTQzNTA5MzYz._V1_FMjpg_UY2963_.jpg" alt=""/>
        </div>
        <div class="col">
          <div class="title">I've been reading...</div>
          <img src="https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1396809742i/17139513.jpg" alt=""/>
        </div>
      </div>
      <div class="resume">Read<span class="junqi arrow-handle"/> or <a href="/static/wang_res.pdf" target="_blank" rel="noopener noreferrer">download<span class="junqi new-tab"/></a> my resume!</div>
      <Heading1 content="Education" />
      <p class="pf">My education spans across many domains of digital product development lifecycle including product design, human-computer interaction, computer science, internet/technology governance, project management, electronic engineering, business administration, etc.</p>
      <div class="range">Aug. 2021 - Dec. 2022</div>
      <div class="school cmu">
        <img alt="CMU seal" class="logo" src="../assets/about/Carnegie_Mellon_University_seal.svg"/>
        <div class="school-name">Carnegie Mellon University</div>
      </div>
      <div class="degree">Master of Integrated Innovation for Products and Services</div>
      <div class="jp-special">A joint product development initiative of the School of Design, the College of Engineering, and the Tepper School of Business.</div>
      <div class="course">Courses</div>
      <div class="courses">
        <div>User Research</div><div>Design Thinking</div><div>Experience Design</div><div>Visual Communication</div><div>Integrated Product Development Methods</div>
        <div>Interaction Techniques</div><div>Consumer Behaviors</div><div>Digital Ethnography</div><div>Launching New Products</div><div>Design of AI Products and Services</div>
        <div>AI: Fairness, Accountability, Transparency, and Ethics</div><div>Human Factors</div><div>Human Experience in Design</div>
      </div>
      <div class="range">Jan. 2022 - Present</div>
      <div class="school gatech">
        <img alt="gatech icon" class="logo" src="../assets/about/Georgia_Tech_seal.svg"/>
        <div class="school-name">Georgia Institute of Technology</div>
      </div>
      <div class="degree">Master of Science in Computer Science</div>
      <div class="jp-special">Interactive Intelligence Specialization</div>
      <div class="course">Courses</div>
      <div class="courses">
        <div>Human-Computer Interaction</div><div>Artificial Intelligence</div><div>Cognitive Science</div><div>Reinforcement Learning</div>
        <div>Mobile and Ubiquitous Computing</div><div>Video Game Design</div><div>Health Informatics</div><div>Education Technologies</div>
      </div>
      <div class="range">Sep. 2016 - Jun. 2020</div>
      <div class="school bupt">
        <img alt="BUPT icon" class="logo" src="https://storage.googleapis.com/junqi-portfolio/about/BUPT.png"/>
        <div class="school-name">Beijing University of Posts and Telecommunications</div>
      </div>
      <div class="degree">Bachelor of Management in e-Commerce Engineering with Law</div>
      <div class="school qmul">
        <img alt="QMUL icon" class="logo" src="../assets/about/qmul.svg"/>
        <div class="school-name">Queen Mary University of London</div>
      </div>
      <div class="degree">Bachelor of Science (Engineering) with Honours in e-Commerce Engineering with Law</div>
      <div class="jp-special">Dual degree, BUPT-QMUL Joint Program. An interdisciplinary program focused in computer science, management, and internet governance.</div>
      <div class="course">Courses</div>
      <div class="courses">
        <div>Product Development</div><div>Advanced Network Programming</div><div>Artificial Intelligence</div><div>Digital Circuit Design</div><div>Telecom Systems</div><div>Data Structure</div><div>Computer Network</div><div>Software Engineering</div><div>Data Structure</div><div>Database Technologies and Applications</div>
        <div>Marketing</div><div>Enterprise Management</div><div>Economics</div><div>e-Commerce Law</div><div>Economic Law</div><div>Intellectual Property</div><div>Information Security and Data Protection Law</div><div>Computer Crime</div><div>Security & Authentication</div>
      </div>
      <Heading1 content="Experience"/>
      <Timeline :timeline-items="dataTimeline"
                :unique-year="true"
                dateLocale="en-US"
                order="desc"/>
      <Heading1 content="Skills & Tools"/>
      <div class="skills">
        <div class="col">
          <div class="skill-title">Development</div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="@/assets/about/Java.svg"></div><div class="skill-name">Java</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="Spring" src="../assets/about/Spring.svg"></div><div class="skill-name">Spring</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="MyBatis" src="https://storage.googleapis.com/junqi-portfolio/about/mybatis.png"></div><div class="skill-name">MyBatis</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="https://storage.googleapis.com/junqi-portfolio/about/javascript.png"></div><div class="skill-name">JavaScript</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="Vue" src="../assets/about/Vue.js.svg"></div><div class="skill-name">Vue.js</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="React" src="../assets/about/React.svg"></div><div class="skill-name">React.js</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="Node" src="../assets/about/Node.svg"></div><div class="skill-name">Node.js</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="../assets/about/Database.svg"></div><div class="skill-name">Database</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="Oracle" src="../assets/about/Oracle.svg"></div><div class="skill-name">Oracle</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="MySQL" src="../assets/about/MySQL.svg"></div><div class="skill-name">MySQL</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="SQL Server" src="https://storage.googleapis.com/junqi-portfolio/about/sql-server.png"></div><div class="skill-name">SQL Server</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="C" src="https://storage.googleapis.com/junqi-portfolio/about/c-language.png"></div><div class="skill-name">C</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Lnux" src="../assets/about/linux.svg"></div><div class="skill-name">Linux</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="MATLAB" src="https://storage.googleapis.com/junqi-portfolio/about/matlab.png"></div><div class="skill-name">MATLAB</div></div>
        </div>
        <div class="col">
          <div class="skill-title">Design</div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="../assets/about/Sketch.svg"></div><div class="skill-name">Sketch</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="../assets/about/Figma.svg"></div><div class="skill-name">Figma</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="../assets/about/Framer.svg"></div><div class="skill-name">Framer</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Axure" src="../assets/about/axure.svg"></div><div class="skill-name">Axure</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="InVision" src="../assets/about/invision.svg"></div><div class="skill-name">InVision</div></div>
          <div class="skill-item"><div class="skill-icon"><img alt="Spring" src="../assets/about/Adobe.svg"></div><div class="skill-name">Adobe Creative Suite</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="PhotoShop" src="../assets/about/Photoshop.svg"></div><div class="skill-name">PhotoShop</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="Premiere" src="../assets/about/Premiere.svg"></div><div class="skill-name">Premiere</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="InDesign" src="../assets/about/InDesign.svg"></div><div class="skill-name">InDesign</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="Illustrator" src="../assets/about/Illustrator.svg"></div><div class="skill-name">Illustrator</div></div>
          <div class="sub-item skill-item"><div class="skill-icon"><img alt="XD" src="../assets/about/Adobe_XD.svg"></div><div class="skill-name">Experience Design</div></div>
        </div>
        <div class="col">
          <div class="skill-title">Skills</div>
          <div class="skill-item"><div class="skill-name">Prototyping</div></div>
          <div class="skill-item"><div class="skill-name">User Research</div></div>
          <div class="skill-item"><div class="skill-name">Interaction Design</div></div>
          <div class="skill-item"><div class="skill-name">Product Design</div></div>
          <div class="skill-item"><div class="skill-name">Frontend Development</div></div>
          <div class="skill-item"><div class="skill-name">Backend Development</div></div>
          <div class="skill-item"><div class="skill-name">Product Management</div></div>
        </div>
      </div>
    </div>
    <GlobalFooter page-name="About"/>
  </div>
</template>
<script>
import Navigation from '../components/NavigationHead.vue'
import Timeline from '../components/Timeline'
import Heading1 from '@/components/PrimaryHeading.vue'
import GlobalFooter from "@/components/GlobalFooter.vue";
import {useMeta} from "vue-meta";
export default {
  components: {Heading1, Timeline, GlobalFooter, Navigation},
  setup () {
    useMeta({
      title: 'About Me',
      description : "Junqi is a product manager with 2+ years of experience and a background in various stages of digital product development process and operation.",
      twitter: {
        title: "About Me | Junqi Wang",
        description: "Junqi is a product manager with 2+ years of experience and a background in various stages of digital product development process and operation.",
        card: "twitter card",
        image: "https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg",
      },
      og: {
        title : 'About Me | Junqi Wang',
        description : 'Junqi is a product manager with 2+ years of experience and a background in various stages of digital product development process and operation.',
        type : 'website',
        url : 'https://junqi.info/about',
        image : 'https://storage.googleapis.com/junqi-portfolio/home/web_thumb.jpg',
        site_name : 'About Me | Junqi Wang',
      }})
  },
  data () {
    return {
      dataTimeline: [
        {
          from: new Date(2022, 11),
          title: 'Anew | Pittsburgh, PA',
          position: 'Product Manager',
          description:
              ['Led and collaborated with a cross-functional team of 3 designers and 3 engineers to successfully' +
              'launch Anew from 0 to 1', 'Implemented post-launch Agile iteration processes, product roadmap, and design delivery procedures']
        },
        {
          from: new Date(2022, 7),
          title: 'BMW Group | Greer, SC',
          position: 'Project Manager',
          description:
              ['Planned and implemented autonomous route trains and designed performance KPIs and dashboards for continuous improvement within BMW’s manufacturing logistics processes, reducing 21 headcount and increasing production units by 12%']
        },
        {
          from: new Date(2022, 4),
          title: 'TikTok | New York, NY',
          position: 'Technical Program Manager Intern',
          description:
            ['Led Global Security Product Review program across 5 product teams to uphold corporate compliance and public trust, covering Penetration Testing, Privacy and Data Protection,  Data Defense and Access Assurance, Vulnerability Management, and Risk Management', 'Delivered a data governance initiative with Global Legal, Privacy Operations, and TikTok Eng to create an operational and technical protocol to enable the ability to process residual data deletion requests and investigate potential data non-compliance']
        },
        {
          from: new Date(2020, 11),
          title: 'NetEase | Beijing, CN',
          position: 'Junior Product Designer',
          description:
            ['Launched Customized Timeline feature to expand into a new user base, improved its conversion rate from 0.02% to 10%, and achieved WoW retention rate of 38%',
              'Developed the interaction design of 2020 Tokyo Olympics featured activities, achieving  DAU of 2+ million',
              'Designed the app skeleton loading framework to improve user perception of waiting time during resource loading',
              'Led the accessible design framework for visually impaired people and elders to access the news app']
        },
        {
          from: new Date(2020, 0),
          title: 'Xiaomi Technology | Beijing, CN',
          position: 'Associate Product Manager',
          pic: 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Xiaomi_logo_%282021-%29.svg',
          description:
            ['Planned and designed Xiaoai game feature, reducing the rate of accidental wake-up by 20%, reducing relating user feedback by 86%, boosting game scenario pv by 15%;',
              'Redesigned Xiaoai Stock domain, increased average UV by 42% on stock exchange days;',
              'Optimized AI models with engineers based on product performance, reducing non-human-computer-interaction queries by 8%.']
        },
        {
          from: new Date(2019, 7),
          title: 'DiDi | Beijing, CN',
          position: 'Product Management Intern',
          pic: '/static/DiDi_logo.svg',
          description:
              ['Planned and Designed DiDi Global CRM auto-categorization, reducing Average-Ticket-Handling-Time by 1.4 min.',
                'Implemented DiDi app Help Center pre-authorization self-help feature, reducing Call-Per-Order by 92% for pre-authorization-related tickets.']
        },
        {
          from: new Date(2019, 4),
          title: 'Daimler AG | Beijing, CN',
          position: 'Software Engineering Intern',
          pic: 'https://www.daimler.com/system/img/img-logo-daimler-svg.svg',
          description:
              ['Developed Project Popeyey, Daimler\'s next-gen truck connectivity platform with Vue.js, which enables truck fleet managers to monitor and improve fleet performance']
        },
        {
          from: new Date(2018, 10),
          title: 'China Telecom Beijing Research Institute | Beijing, CN',
          position: 'Software Engineering Intern',
          description:
              ['Implemented a nationwide base station traffic monitor and forecast site front-end with React.js to allow local carrier maintenance teams to monitor and predict base station status']
        }
      ],
      iconColor: {
        mail: '#000000',
        linkedin: '#000000'
      }
    }
  },
  methods: {
    iconLeave () {
      this.iconColor.mail = '#000000'
      this.iconColor.linkedin = '#000000'
    },
    iconHover (option) {
      switch (option) {
        case 0:
          this.iconColor.mail = '#cccccc'
          break
        case 1:
          this.iconColor.linkedin = '#cccccc'
          break
      }
    },
    iconRedirect (option) {
      switch (option) {
        case 0:
          window.open('mailto:juqwang@gmail.com')
          this.iconColor.mail = '#cccccc'
          break
        case 1:
          window.open('https://www.linkedin.com/in/jq-wang/')
          this.iconColor.linkedin = '#cccccc'
          break
      }
    }
  }
}
</script>
<style lang="scss">
  @import "../styles/variables";
  .about {
    .nav-bar {
      position: absolute;
    }

    .footer-icon {
      text-align: left;
      margin: 40px 0 0 0;
      width: auto;
      svg {
        height: 40px;
        width: 40px;
        cursor: $hover;
      }
    }
    .about-me {
      padding: 150px 0 20px 0;
      font-size: 81px;
      font-weight: 200;
      z-index: 0;
    }
    .about-intro {
      font-size: 30px;
      span {
        color: #aaaaaa;
        font-weight: 500;
      }
    }
    .intro {
      font-size: 20px;
      padding: 10px 0;
    }
    .content {
      text-align: left;
      padding-top: 80px;
      .about-title {
        text-align: left;
        font-size: 28px;
        font-weight: 600;
        margin: 60px 0 20px 0;
      }
      .recent {
        display: grid;
        margin: 80px auto;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 90px;
        @media(max-width: $lg) {
          column-gap: 60px;
        }
        .col {
          .title {
            font-size: 30px;
            margin-bottom: 20px;
            width: 260px;
          }
          img {
            width: 100%;
          }
        }
      }
      .resume {
        font-size: 24px;
        margin-top: 200px;
        .arrow-handle {
          font-size: 28px;
        }
        a {
          span {
            font-weight: 700;
            font-size: 16px;
            vertical-align: top;
          }
        }
      }
      .timeline {
        margin: 20px 0;
      }
      .jp-special {
        color: #aaaaaa;
        font-size: 14px;
        margin: 4px 0 0 15px;
      }
      .range {
        color: #1d1d1d;
        font-size: 16px;
        margin: 40px 0 0 0;
        font-weight: bold;
      }
      .school {
        margin: 10px 0 0 0;
        border-radius: 8px;
        width: max-content;
        height: 30px;
        display: flex;
        &.cmu {
          background-image: linear-gradient(to right, rgba(187, 0, 0, 0.2), rgba(120, 120, 120, 0.2));
          color: #bb0000;
        }
        &.gatech {
           background-image: linear-gradient(to right, rgba(179, 163, 105, 0.2), rgba(240, 240, 240, 0.2));
           color: #b3a369;
         }
        &.bupt {
          background-image: linear-gradient(to right, rgba(0, 138, 58, 0.2), rgba(1, 58, 137, 0.2));
          color: #00873A;
        }
        &.qmul {
          background-image: linear-gradient(to right, rgba(13, 50, 115, 0.2), rgba(123, 14, 114, 0.2));
          color: #0D3273;
        }
        .logo {
          height: 30px;
          margin-left: -13px;
          display: inline-block;
        }
        .school-name {
          font-size: 16px;
          display: inline-block;
          line-height: 30px;
          margin: 0 12px 0 6px;
          height: 30px;
          width: max-content;
        }
      }
      .degree {
        font-size: 14px;
        font-weight: 600;
        margin: 4px 0 0 15px;
      }
      .course {
        font-size: 14px;
        margin: 15px 0 0 15px;
        color: #000000;
      }
      .courses {
        font-size: 14px;
        margin: 0 0 20px 7px;
        max-width: 1100px;
        div {
          border-radius: 4px;
          padding: 6px 8px 4px 8px;
          margin: 4px;
          display: inline-block;
          box-shadow: 0 0 4px 0 rgba(29,29,29,0.4);
        }
      }
      .skills {
        display: flex;
        .col {
          display: inline-block;
          width: 33.333%;
          &:nth-child(2) {
            padding: 0 16%;
          }
          .skill-title {
            text-align: left;
            font-size: 28px;
            color: #aaaaaa;
            padding: 6px 0;
            font-weight: 500;
            line-height: 30px;
          }
          .skill-item {
            display: flex;
            font-weight: 600;
            margin: 15px 0 4px 0;
            box-shadow: 0 0 10px 0 rgba(29,29,29,0.4);
            padding: 0 12px 0 2px;
            width: max-content;
            border-radius: 4px;
            &.sub-item {
              margin-left: 32px;
              font-weight: 400;
              margin-top: 0;
            }
            .skill-icon {
              height: 18px;
              width: 18px;
              margin: 8px;
              display: inline-block;
              img {
                height: 100%;
              }
            }
            .skill-name {
              display: inline-block;
              line-height: 28px;
              height: 28px;
              padding-top: 4px;
              padding-left: 4px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media(min-width: $lg) {
    .about {
      .about-me {
        top: -30px;
      }
      .content {
        padding-top: 200px;
      }
    }
  }
</style>
