<template>
    <div class="heading2">
      <div class="title" v-html="content"></div>
    </div>
</template>

<script>
export default {
  name: 'secondary-heading',
  props: {
    content: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.heading2 {
  max-width: 50%;
  .title {
    font-size: 26px;
    font-weight: 500;
    color: #272733;
    width: max-content;
    margin: 40px 0 30px 0;
    span {
      color: #FF473A;
      font-size: 14px;
      border: #FF473A 1px solid;
      padding: 0 6px;
      margin: 0 0 0 8px;
      vertical-align: middle;
      line-height: inherit;
      font-weight: 300;
      border-radius: 4px;
    }
  }
}
</style>
