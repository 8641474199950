import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createMetaManager } from 'vue-meta';
import VueGtag from "vue-gtag";

import '@/styles/base.scss'
import '@/styles/initialize.css'

const app = createApp(App)
app.use(router)
app.use(createMetaManager())
app.use(VueGtag, {
    config: { id: "G-7BCLQ7D6NN" }
})
app.mount('#app')